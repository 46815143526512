import { ReplyingUsername } from "../common";
import IconButton from "../common/IconButton";

import {
  Content,
  Container,
  Head,
  Time,
  Action,
  Image,
  Bio,
  TweetType,
  CWrapper,
  Line,
  Sidebar,
} from "./TweetCard.styles";

interface ITwitterCardProps {
  children?: React.ReactNode;
}

const TweetCard = ({ children }: ITwitterCardProps) => {
  return <Container>{children}</Container>;
};

TweetCard.Image = Image;
TweetCard.Content = Content;
TweetCard.Head = Head;
TweetCard.Action = Action;
TweetCard.IconButton = IconButton;
TweetCard.Bio = Bio;
TweetCard.Time = Time;
TweetCard.TweetType = TweetType;
TweetCard.CWrapper = CWrapper;
TweetCard.Username = ReplyingUsername;
TweetCard.Line = Line;
TweetCard.Sidebar = Sidebar;

export default TweetCard;
