import { useEffect } from "react";
import { usePendo } from "hooks/common";
import { AdminEngagementProvider } from "context";
import { AdminEngagement as AdminEngagementForm } from "components/Twitter/LeadGeneration";

const AdminEngagement = () => {
  const { initializePendo } = usePendo();

  useEffect(() => {
    initializePendo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AdminEngagementProvider>
      <AdminEngagementForm />
    </AdminEngagementProvider>
  );
};

export default AdminEngagement;
