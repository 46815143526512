import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const UpdateFormWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  align-self: center;
  row-gap: ${helper.getSpacing("small")};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
`;

const CriteriaWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: ${helper.getSpacing("small")};
`;

export { UpdateFormWrapper, ButtonWrapper, CriteriaWrapper };
