import React from "react";
import ErrorPage from "../ErrorPage";
const { REACT_APP_DOLPHY_ROOT_URL: frontendRoot } = process.env;

const ErrorFallback = () => {
  return (
    <ErrorPage
      message="Something went wrong!"
      button
      onClick={() => (window.location.href = `${frontendRoot}`)}
    />
  );
};

export default ErrorFallback;
