import { useEffect } from "react";
import { usePendo } from "hooks/common";
import { LeadNurturingProvider, PostSchedulerProvider } from "context";
import {
  EngagementForm,
  EngagementList,
} from "components/Twitter/LeadGeneration/LeadNurturing";
import { PostScheduler } from "components/Twitter";

const LeadNurturing = () => {
  const { initializePendo } = usePendo();

  useEffect(() => {
    initializePendo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LeadNurturingProvider>
      <EngagementForm />
      <EngagementList />
      <PostSchedulerProvider>
        <PostScheduler />
      </PostSchedulerProvider>
    </LeadNurturingProvider>
  );
};

export default LeadNurturing;
