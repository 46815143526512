import { DateTimePicker } from "components/common";
import { Flex } from "components/core";
import { isToday } from "date-fns";
import { useField } from "formik";
import { IScheduleRetweetCreateFormValues } from "context/Twitter/types";

interface IDateTimeProps {
  name: string;
}

const ScheduleDateTime = (props: IDateTimeProps) => {
  const [field, , helpers] = useField<
    IScheduleRetweetCreateFormValues["datetime"]
  >(props.name);

  return (
    <Flex justify="space-between">
      <DateTimePicker>
        <DateTimePicker.DatePicker
          value={field.value}
          onChange={(newDate) => helpers.setValue(newDate)}
          minDate={new Date()}
          style={{ boxShadow: "unset", height: "2.5rem" }}
        />
        <DateTimePicker.TimePicker
          value={field.value}
          onChange={(newDate) => helpers.setValue(newDate)}
          minTime={isToday(field.value) ? new Date() : undefined}
          style={{ boxShadow: "unset", height: "2.5rem" }}
        />
      </DateTimePicker>
    </Flex>
  );
};

export default ScheduleDateTime;
