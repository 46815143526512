import { useState } from "react";
import { Dropdown, IconButton } from "components/core";
import { sortByInfluencerDataBase } from "config/Twitter";
import { useInfluencers } from "hooks/Twitter";
import { SortIcon } from "../../common/icons";
import { SortByWrapper, SortByPreview } from "./InfluencerSortBy.styles";
import { useOnClickOutside } from "hooks";

const InfluencerSortBy = () => {
  const influencers = useInfluencers();
  const [showDropdown, setShowDropdown] = useState(false);

  const onOutSideClick = () => {
    setShowDropdown(false);
  };

  const previewRef = useOnClickOutside<HTMLDivElement>(onOutSideClick);

  const renderDropdown = () => {
    if (showDropdown)
      return (
        <SortByPreview ref={previewRef}>
          <Dropdown
            style={{
              minWidth: "190px",
              height: "fit-content",
            }}
            placeholder="Highest Followers"
            options={sortByInfluencerDataBase}
            onChange={(e, { value }) =>
              typeof value === "string" && influencers.handleFilter(value)
            }
          />
        </SortByPreview>
      );
  };

  return (
    <SortByWrapper>
      <IconButton
        variant="transparent"
        icon={SortIcon}
        data-tip="Sort"
        onClick={() => setShowDropdown(true)}
      />
      {renderDropdown()}
    </SortByWrapper>
  );
};

export default InfluencerSortBy;
