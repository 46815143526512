import { useState } from "react";
import { LinkButton } from "components/core";
import { Linkify } from "components/common";
import { useToggle, useTwitterAuth } from "hooks";
import { useKeywordListening } from "hooks/Twitter";
import { useAppDispatch } from "redux/store/hooks";
import { setConnectTwitter } from "redux/slices/Twitter";
import { DMCard } from "../common";
import { DMIcon } from "../common/icons";
import {
  About,
  Bio,
  CardContainer,
  Content,
  Name,
  ProfileImage,
  Stat,
  Stats,
  Username,
  UserName,
  CloseIcon,
  TwitterIcon,
  WebsiteIcon,
  StyledIcon,
  MapIcon,
} from "./UserInfoCard.styles";

type TUserInfoCardProps = {
  name: string;
  username: string;
  profileImage: string;
  bio: string;
  followers: number;
  followings: number;
  url: string;
  website: string;
  totalTweets: number;
  location: string;
  open: boolean;
  onClose: () => void;
};

const UserInfoCard = ({
  name,
  username,
  profileImage,
  bio,
  followers,
  followings,
  url,
  website,
  totalTweets,
  location,
  open,
  onClose,
}: TUserInfoCardProps) => {
  const { handleDM } = useKeywordListening();
  const { isSignedIn } = useTwitterAuth();
  const dispatch = useAppDispatch();

  const [replyOpen, toggleReply] = useToggle();
  const [files, setFiles] = useState<File[]>([]);
  const [text, setText] = useState<string>("");

  const openLink = (url: string) => window.open(url, "_blank");

  if (!open) return null;

  return (
    <CardContainer>
      <CloseIcon onClick={() => onClose()} />
      <StyledIcon
        icon={DMIcon}
        onClick={() => {
          if (isSignedIn) {
            toggleReply(true);
          } else {
            dispatch(setConnectTwitter(true));
          }
        }}
      />
      <About>
        <ProfileImage src={profileImage} alt={name} />
        <UserName>
          <Name onClick={() => openLink(url)}>{name}</Name>
          <Username onClick={() => openLink(url)}>@{username}</Username>
        </UserName>
      </About>
      <Content>
        <Bio>
          <Linkify>{bio}</Linkify>
        </Bio>
        <Stats>
          <Stat>
            {Intl.NumberFormat("en-US", {
              notation: "compact",
              maximumFractionDigits: 1,
            }).format(followings)}
            <span> Following</span>
          </Stat>
          <Stat>
            {Intl.NumberFormat("en-US", {
              notation: "compact",
              maximumFractionDigits: 1,
            }).format(followers)}
            <span> Followers</span>
          </Stat>
        </Stats>
        <Stats>
          <Stat>
            <TwitterIcon />{" "}
            {Intl.NumberFormat("en-US", {
              notation: "compact",
              maximumFractionDigits: 1,
            }).format(totalTweets)}
          </Stat>
          {website && (
            <Stat>
              <WebsiteIcon />{" "}
              <LinkButton
                content="bit.ly/link"
                onClick={() => openLink(website)}
              />
            </Stat>
          )}
        </Stats>
        <Stats>
          {location && (
            <Stat>
              <MapIcon /> {location}
            </Stat>
          )}
        </Stats>
        <DMCard
          open={replyOpen}
          onCancel={() => toggleReply(false)}
          files={files}
          onFilesChange={(files) => setFiles(files)}
          text={text}
          onTextChange={(value) => setText(value)}
          onSave={() => {
            handleDM({ username, files, text });
            setText("");
            setFiles([]);
            toggleReply(false);
          }}
        />
      </Content>
    </CardContainer>
  );
};

export default UserInfoCard;
