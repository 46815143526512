import { Form } from "components/core";
import { languagesListConfig } from "config/common";
import { Formik } from "formik";
import { useGeneralPreferences } from "hooks/Profile";
import { useKeywordListening } from "hooks/Twitter";
import { setAdvancedSearchIsOpen } from "redux/slices/Twitter";
import { useAppDispatch, useAppSelector } from "redux/store/hooks";
import { Portal } from "semantic-ui-react";
import AdvancedSearchDate from "./AdvancedSearchDates";
import AdvancedSortBy from "./Leads/AdvancedSortBy";
import { Container } from "./AdvancedSearch.styles";
import { useMediaQuery } from "react-responsive";

const AdvancedSearch = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(
    (state) => state.twitter.tweetSearch.advancedSearchIsOpen
  );
  const generalPreferences = useGeneralPreferences();
  const isMobileScreen = useMediaQuery({
    query: "(min-width: 1024px)",
  });

  const { handleAdvancedSearch } = useKeywordListening();

  const handleModalClose = () => {
    dispatch(setAdvancedSearchIsOpen(false));
  };

  const initialFormValues = {
    startDate: new Date(Date.now() - 86400000),
    endDate: new Date(),
    isReply: false,
    likes: 0,
    replies: 0,
    retweets: 0,
    language: generalPreferences.details.language,
  };

  return (
    <Portal open={isOpen}>
      <Container>
        <Formik
          initialValues={initialFormValues}
          onSubmit={handleAdvancedSearch}
        >
          {({ handleSubmit }) => (
            <Form.Form onSubmit={handleSubmit}>
              {isMobileScreen ? (
                <>
                  <Form.Header
                    content="Sort Tweets by"
                    variant="alpha"
                    textAlign="left"
                    font="poppins"
                    mb="small"
                  />
                  <AdvancedSortBy />
                </>
              ) : null}
              <Form.Header
                content="Advanced Search"
                variant="alpha"
                textAlign="left"
                font="poppins"
                mb="small"
                mt="medium"
              />
              <Form.Field
                style={{
                  display: "flex",
                  gap: "1rem",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Form.Label content="Minimum Likes" style={{ margin: "4px" }} />
                <Form.Input
                  name="likes"
                  type="number"
                  style={{ boxShadow: "unset", height: "40px", width: "158px" }}
                />
              </Form.Field>
              <Form.Field
                style={{
                  display: "flex",
                  gap: "1rem",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Form.Label
                  content="Minimum Replies"
                  style={{ margin: "4px" }}
                />
                <Form.Input
                  name="replies"
                  type="number"
                  style={{ boxShadow: "unset", height: "40px", width: "158px" }}
                />
              </Form.Field>
              <Form.Field
                style={{
                  display: "flex",
                  gap: "1rem",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Form.Label
                  content="Minimum Retweets"
                  style={{ margin: "4px" }}
                />
                <Form.Input
                  name="retweets"
                  type="number"
                  style={{ boxShadow: "unset", height: "40px", width: "158px" }}
                />
              </Form.Field>
              <Form.Field
                style={{
                  display: "flex",
                  gap: "1rem",
                  alignItems: "center",
                }}
              >
                <Form.Label
                  content="Include Replies"
                  style={{ margin: "4px" }}
                />
                <Form.Checkbox
                  label=""
                  name="isReply"
                  style={{ marginLeft: "36px" }}
                />
              </Form.Field>
              <Form.Field
                style={{
                  display: "flex",
                  gap: "1rem",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Form.Label content="Language" style={{ margin: "4px" }} />
                <Form.DropdownField
                  name="language"
                  options={languagesListConfig}
                  style={{ width: "158px" }}
                />
              </Form.Field>
              <AdvancedSearchDate startDate="startDate" endDate="endDate" />
              <Form.Field
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                }}
              >
                <Form.Button
                  variant="alpha"
                  mt="small"
                  size="mini"
                  content="Cancel"
                  onClick={handleModalClose}
                  outline
                />
                <Form.Button
                  variant="alpha"
                  type="submit"
                  mt="small"
                  size="mini"
                  content="Search"
                  outline
                />
              </Form.Field>
            </Form.Form>
          )}
        </Formik>
      </Container>
    </Portal>
  );
};

export default AdvancedSearch;
