import { Button, Flex } from "components/core";
import { ScheduleRetweetFormContainer } from "./ScheduleRetweetForm.styles";
import SelectInfluencer from "./SelectInfluencer";
import SelectCriteria from "./SelectCriteria";
import ScheduleDateTime from "./ScheduleDateTime";
import { Formik } from "formik";
import { IScheduleRetweetCreateFormValues } from "context/Twitter/types";
import { useScheduleRetweet } from "hooks/Twitter";

const initialState = {
  influencer: "",
  highest_liked: true,
  datetime: new Date(),
};

const ScheduleRetweetForm = () => {
  const schedule = useScheduleRetweet();

  const handleFormikSubmit = ({
    influencer,
    highest_liked,
    datetime,
  }: IScheduleRetweetCreateFormValues) => {
    schedule.handleSchedule({
      influencer,
      highest_liked,
      datetime,
    });
  };

  return (
    <Formik initialValues={initialState} onSubmit={handleFormikSubmit}>
      {(formik) => (
        <>
          <ScheduleRetweetFormContainer>
            <ScheduleDateTime name="datetime" />
            <SelectInfluencer name="influencer" />
            <SelectCriteria name="highest_liked" />
            <Flex>
              <Button
                variant="alpha"
                content="Schedule"
                type="submit"
                size="mini"
                onClick={() => {
                  formik.handleSubmit();
                }}
              />
            </Flex>
          </ScheduleRetweetFormContainer>
        </>
      )}
    </Formik>
  );
};

export default ScheduleRetweetForm;
