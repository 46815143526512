import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const BodyWrapper = styled.div`
  padding-inline-end: ${helper.getSpacing("medium")};
  display: grid;
  grid-template-columns: 6fr 4fr;
  column-gap: 2rem;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: ${helper.getSpacing("extraSmall")};
  width: fit-content;
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ReplyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${helper.getSpacing("tiny")};
  border: 1px solid ${({ theme }) => theme.colors.extraLightGrey};
  border-radius: ${helper.getSpacing("small")};
  padding: ${helper.getSpacing("small")};
  height: max-content;
`;

const CardContainer = styled.div`
  ${helper.centerContent()};
  flex-direction: column;
  gap: ${helper.getSpacing("small")};
  min-width: 180px;
  border: 1px solid ${({ theme }) => theme.colors.success};
  ${helper.getBoxShadow("darkGrey", { intensity: "high" })}
  border-radius: 10px;
  padding: ${helper.getSpacing("small")};
  text-align: center;
`;

export {
  Actions,
  BodyWrapper,
  SectionContainer,
  ReplyContainer,
  CardContainer,
};
