import { IEngagementTweet } from "adapters/Twitter/types";
import { Linkify } from "components/common";
import { TweetCard } from "components/Twitter/common";
import { useToggle, useTwitterAuth } from "hooks";
import { useLeadNurturing } from "hooks/Twitter";
import { useState } from "react";
import {
  setConnectTwitter,
  setSubNavOpen,
  setTweetText,
} from "redux/slices/Twitter";
import { useAppDispatch, useAppSelector } from "redux/store/hooks";
import TweetReplyCard from "../TweetReplyCard";
import TweetSearchActions from "../TweetSearchActions";
import { Container } from "./NurturingCard.styles";

type TNurturingCard = {
  tweet: IEngagementTweet;
};

const NurturingCard: React.FC<TNurturingCard> = ({ tweet }) => {
  const [files, setFiles] = useState<File[]>([]);
  const [text, setText] = useState("");
  const [isTweetReplyCardOpen, toggleTweetReplyCard] = useToggle();
  const { handleLike, handleReply, handleRetweet, handleScheduleRetweet } =
    useLeadNurturing();
  const { isSignedIn } = useTwitterAuth();
  const dispatch = useAppDispatch();
  const postNow = useAppSelector((state) => state.twitter.tweetSearch.postNow);

  return (
    <Container>
      <TweetCard>
        <TweetCard.Content>
          <TweetCard.Head>
            <TweetCard.Image src={tweet.profileImageUrl} alt={tweet.username} />
            <TweetCard.Username
              name={tweet.name}
              username={tweet.username}
              createdAt={new Date(tweet.createdAt)}
            />
          </TweetCard.Head>
          <TweetCard.TSBio>
            <Linkify>{tweet.text}</Linkify>
          </TweetCard.TSBio>
          <TweetCard.Action>
            <TweetSearchActions
              likes={tweet.likes}
              retweets={tweet.retweets}
              isLiked={tweet.isLiked}
              onLikeClick={() => {
                if (isSignedIn) {
                  handleLike({
                    tweetId: tweet.tweetId,
                    isLiked: tweet.isLiked,
                  });
                } else {
                  dispatch(setConnectTwitter(true));
                }
              }}
              onReplyClick={() => {
                if (isSignedIn) {
                  toggleTweetReplyCard();
                } else {
                  dispatch(setConnectTwitter(true));
                }
              }}
              onRetweetClick={(datetime) => {
                if (isSignedIn) {
                  if (postNow) {
                    handleRetweet({
                      tweetId: tweet.tweetId,
                      datetime,
                    });
                  } else {
                    handleScheduleRetweet({
                      tweetId: tweet.tweetId,
                      twitterUsername: tweet.username,
                      datetime,
                    });
                  }
                } else {
                  dispatch(setConnectTwitter(true));
                }
              }}
              onEditClick={() => {
                dispatch(setTweetText(tweet.text));
                dispatch(setSubNavOpen());
              }}
            />
          </TweetCard.Action>
        </TweetCard.Content>
      </TweetCard>
      <TweetReplyCard
        tweetId={tweet.tweetId}
        tweetText={tweet.text}
        open={isTweetReplyCardOpen}
        onCancel={() => toggleTweetReplyCard(false)}
        files={files}
        onFilesChange={(files) => setFiles(files)}
        text={text}
        onTextChange={(value) => setText(value)}
        onSave={() => {
          handleReply({ tweetId: tweet.tweetId, files, text });
          setText("");
          setFiles([]);
          toggleTweetReplyCard(false);
        }}
      />
    </Container>
  );
};

export default NurturingCard;
