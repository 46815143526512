import { useEffect } from "react";
import { usePendo } from "hooks/common";
import {
  TweetSearchBar,
  AdvancedSearch,
  TweetSearchList,
} from "components/Twitter/LeadGeneration";
import { DropTarget } from "components/Twitter/LeadGeneration";
import {
  PostSchedulerProvider,
  RecentTweetsProvider,
  KeywordListeningProvider,
} from "context/Twitter";
import { RecentTweetList } from "components/Twitter/RecentTweets";
import { PostScheduler } from "components/Twitter";
import { ConnectTwitter } from "components/Twitter/common";
import { useAppSelector } from "redux/store/hooks";

const KeywordListening = () => {
  const { initializePendo } = usePendo();

  const connectTwitter = useAppSelector(
    (state) => state.twitter.common.connectTwitter
  );

  useEffect(() => {
    initializePendo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <KeywordListeningProvider>
      <RecentTweetsProvider>
        <RecentTweetList />
      </RecentTweetsProvider>
      {connectTwitter ? <ConnectTwitter connect /> : null}
      <TweetSearchBar />
      <AdvancedSearch />
      <DropTarget />
      <TweetSearchList />
      <PostSchedulerProvider>
        <PostScheduler />
      </PostSchedulerProvider>
    </KeywordListeningProvider>
  );
};

export default KeywordListening;
