import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ThemeProvider } from "styled-components";
import { GlobalStyle, theme } from "theme";
import dolphynReduxStore from "./redux/store";
import { ToastContainer } from "react-toastify";
import {
  CustomFitAiProvider,
  GoogleTagManagerProvider,
  UserSnapProvider,
} from "context/common";
import { constants } from "config/common";
import App from "./App";

import "theme/semantic-ui-css/semantic.css";
import "react-toastify/dist/ReactToastify.css";

const queryClient = new QueryClient();

createRoot(document.getElementById("root") as HTMLDivElement).render(
  <Provider store={dolphynReduxStore}>
    <QueryClientProvider client={queryClient}>
      <GoogleTagManagerProvider gtmId={constants.GTM_ID}>
        <UserSnapProvider>
          <CustomFitAiProvider>
            <DndProvider backend={HTML5Backend}>
              <BrowserRouter>
                <ThemeProvider theme={theme}>
                  <GlobalStyle />
                  <App />
                  <ToastContainer
                    position="top-right"
                    hideProgressBar
                    closeOnClick={false}
                  />
                </ThemeProvider>
              </BrowserRouter>
            </DndProvider>
          </CustomFitAiProvider>
        </UserSnapProvider>
      </GoogleTagManagerProvider>
    </QueryClientProvider>
  </Provider>
);
