import { Dropdown } from "components/core";
import { criteriaConfig } from "config/Twitter";
import { IScheduleRetweetCreateFormValues } from "context/Twitter/types";
import { useField } from "formik";

interface ISelectCriteriaProps {
  name: string;
}

const SelectCriteria = (props: ISelectCriteriaProps) => {
  const [field, , helpers] = useField<
    IScheduleRetweetCreateFormValues["highest_liked"]
  >(props.name);

  return (
    <Dropdown
      placeholder="Select Criteria"
      options={criteriaConfig}
      selection
      value={field.value}
      onChange={(e, { value }) =>
        typeof value === "boolean" && helpers.setValue(value)
      }
    />
  );
};

export default SelectCriteria;
