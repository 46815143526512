import { motion } from "framer-motion";
import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const ListWrapper = styled(motion.div)`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 0 12px 0 ${({ theme }) => theme.colors.extraLightGrey};
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: ${helper.getSpacing("small")};
  padding: ${helper.getSpacing("small")} 0 0;
  margin: 0 auto;
  left: 0;
  right: 0;
  z-index: 1;
  width: 56%;
  height: 80vh;

  @media screen and (max-width: 1024px) {
    width: 72%;
  }
  @media screen and (max-width: 768px) {
    width: 80%;
  }
  @media screen and (max-width: 425px) {
    width: 96%;
  }
`;

const TweetList = styled.div`
  padding: ${helper.getSpacing("extraSmall")} ${helper.getSpacing("small")};
  display: flex;
  flex-direction: column;
  gap: ${helper.getSpacing("extraSmall")};
  overflow: scroll;
  ${helper.getStyledScrollbar()};
`;

export { ListWrapper, TweetList };
