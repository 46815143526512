import { Dropdown } from "components/core";
import { IScheduleRetweetCreateFormValues } from "context/Twitter/types";
import { useField } from "formik";
import { useInfluencersList } from "hooks/Twitter";

interface ISelectInfluencerProps {
  name: string;
}

const SelectInfluencer = (props: ISelectInfluencerProps) => {
  const influencersList = useInfluencersList();

  const [field, , helpers] = useField<
    IScheduleRetweetCreateFormValues["influencer"]
  >(props.name);

  const InfluencersListObject = influencersList.data.influencerList.map(
    (influencer) => ({ key: influencer, value: influencer, text: influencer })
  );

  return (
    <Dropdown
      placeholder="Select Influencer"
      options={InfluencersListObject}
      selection
      value={field.value}
      onChange={(e, { value }) =>
        typeof value === "string" && helpers.setValue(value)
      }
    />
  );
};

export default SelectInfluencer;
