import { IAdminEngagementFormValues } from "context/Twitter/types";
import { useField } from "formik";
import { Form } from "components/core";
import { Actions } from "./AdminEngagement.styles";

interface IFormActionsProps {
  name: {
    like: string;
    reply: string;
  };
  onLike: () => void;
  onReply: () => void;
}

const FormActions = (props: IFormActionsProps) => {
  const [likeField, , likeHelpers] = useField<
    IAdminEngagementFormValues["like"]
  >(props.name.like);

  const [replyField, , replyHelpers] = useField<
    IAdminEngagementFormValues["reply"]
  >(props.name.reply);

  return (
    <Actions>
      <Form.Button
        content="Like"
        variant="alpha"
        size="mini"
        active={likeField.value}
        onClick={() => {
          props.onLike();
          likeHelpers.setValue(!likeField.value);
        }}
        toggle
        outline
      />
      <Form.Button
        content="Reply"
        variant="alpha"
        size="mini"
        active={replyField.value}
        onClick={() => {
          props.onReply();
          replyHelpers.setValue(!replyField.value);
        }}
        toggle
        outline
      />
    </Actions>
  );
};

export default FormActions;
