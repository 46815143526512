import { TweetCard } from "../common";
import { TConversation } from "adapters/Twitter/types";

type TRecentTweetActionsProps = {
  data: TConversation;
  onReplyClick: () => void;
  onLikeClick: () => void;
  onRetweetClick: (datetime: Date) => void;
};

const RecentReplyAction = ({
  data,
  onReplyClick,
  onLikeClick,
  onRetweetClick,
}: TRecentTweetActionsProps) => {
  return (
    <>
      <TweetCard.LikeButton
        count={data.likeCount}
        isLiked={data.likeStatus}
        onClick={onLikeClick}
      />
      <TweetCard.InstantReplyButton
        count={data.replyCount}
        onClick={onReplyClick}
      />
      <TweetCard.RetweetButton
        count={data.retweetCount}
        onSubmit={onRetweetClick}
      />
    </>
  );
};

export default RecentReplyAction;
