import { useEffect, useState } from "react";
import { Formik } from "formik";
import ReactTooltip from "react-tooltip";
import { useInfluencers } from "hooks/Twitter";
import { Button, Flex, Form, IconButton } from "components/core";
import { FilterIcon } from "components/Twitter/common/icons";
import { FilterWrapper, FormContainer } from "./InfluencerFilter.styles";
import { useOnClickOutside } from "hooks";
import { IInfluencerFilterFormValues } from "context/Twitter/types";
import { CloseIcon } from "components/Twitter/common/Common.styles";
import { useAppSelector, useAppDispatch } from "redux/store/hooks";
import {
  setBioExcludesI,
  setBioIncludesI,
  setFilterKeywordsI,
  setLocationI,
} from "redux/slices/Twitter";

const InfluencerFilter = () => {
  const [showFilterbox, setShowFilterbox] = useState(false);
  const { handleFilterSearch } = useInfluencers();

  const dispatch = useAppDispatch();
  const bioIncludes = useAppSelector(
    (state) => state.twitter.influencer.bioIncludes
  );
  const bioExcludes = useAppSelector(
    (state) => state.twitter.influencer.bioExcludes
  );
  const location = useAppSelector((state) => state.twitter.influencer.location);
  const filterKeywords = useAppSelector(
    (state) => state.twitter.influencer.filterKeywords
  );

  const initialFormValues: IInfluencerFilterFormValues = {
    filterKeywords,
    location,
    bioIncludes,
    bioExcludes,
  };

  const onOutSideClick = () => {
    setShowFilterbox(false);
  };

  const formRef = useOnClickOutside<HTMLDivElement>(onOutSideClick);

  const handleFormikSearch = ({
    bioIncludes,
    bioExcludes,
    filterKeywords,
    location,
  }: IInfluencerFilterFormValues) => {
    handleFilterSearch({
      bioIncludes,
      bioExcludes,
      filterKeywords,
      location,
    });
    setShowFilterbox(false);
    dispatch(setBioIncludesI(bioIncludes));
    dispatch(setBioExcludesI(bioExcludes));
    dispatch(setLocationI(location));
    dispatch(setFilterKeywordsI(filterKeywords));
  };

  const renderFilterbox = () => {
    if (showFilterbox) {
      return (
        <FormContainer ref={formRef}>
          <CloseIcon onClick={onOutSideClick} />
          <Formik
            initialValues={initialFormValues}
            onSubmit={handleFormikSearch}
          >
            {({ handleSubmit }) => (
              <Form.Form onSubmit={handleSubmit}>
                <Form.Field>
                  <Form.Label content="Bio (includes)" />
                  <Form.MultiSelectDropdownField
                    name="bioIncludes"
                    placeholder="Marketing, bitcoin, Start Up"
                    style={{ boxShadow: "unset" }}
                  />
                </Form.Field>
                <Form.Field>
                  <Form.Label content="Bio (excludes)" />
                  <Form.MultiSelectDropdownField
                    name="bioExcludes"
                    placeholder="Marketing, bitcoin, Start Up"
                    style={{ boxShadow: "unset" }}
                  />
                </Form.Field>
                <Form.Field>
                  <Form.Label content="Location" />
                  <Form.MultiSelectDropdownField
                    name="location"
                    placeholder="New Delhi, New York, Tokyo"
                    style={{ boxShadow: "unset" }}
                  />
                </Form.Field>
                <Form.Field>
                  <Form.Label content="Filter Keywords" />
                  <Form.MultiSelectDropdownField
                    name="filterKeywords"
                    placeholder="Marketing, bitcoin, Start Up"
                    style={{ boxShadow: "unset" }}
                  />
                </Form.Field>
                <Flex justify="end" mt="medium">
                  <Button
                    icon="search"
                    variant="alpha"
                    content="Search"
                    type="submit"
                    size="small"
                  />
                </Flex>
              </Form.Form>
            )}
          </Formik>
        </FormContainer>
      );
    }
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <FilterWrapper>
      <IconButton
        variant="transparent"
        icon={FilterIcon}
        data-tip="Filter"
        onClick={() => setShowFilterbox(true)}
      />
      {renderFilterbox()}
    </FilterWrapper>
  );
};

export default InfluencerFilter;
