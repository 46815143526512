import { Fragment, useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { Linkify } from "components/core";
import { useToggle, useTwitterAuth } from "hooks";
import { useRecentTweetContext } from "hooks/Twitter";
import { TRecentTweets } from "adapters/Twitter/types";
import RecentTweetActions from "./RecentTweetAction";
import TweetCard from "./TweetCard";
import TweetReplyCard from "../LeadGeneration/TweetReplyCard";
import RecentReplyCard from "./RecentReplyCard";
import { relativeTime, timeSince } from "helpers";
import { ConnectTwitter } from "../common";

type RecentTweetCardProps = {
  tweet: TRecentTweets;
};

const RecentTweetCard = ({ tweet }: RecentTweetCardProps) => {
  const { handleLike, handleReply, handleRetweet } = useRecentTweetContext();
  const { isSignedIn } = useTwitterAuth();

  const [isTweetReplyCardOpen, toggleTweetReplyCard] = useToggle();
  const [files, setFiles] = useState<File[]>([]);
  const [text, setText] = useState<string>("");
  const [openConnectTwitter, setConnectTwitter] = useState(false);

  const renderCreatedAt = () => {
    if (!tweet.createdAt) return null;
    const createdAtTime = timeSince(new Date(tweet.createdAt));

    return (
      <span data-tip={relativeTime(tweet.createdAt)}>{createdAtTime}</span>
    );
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  if (openConnectTwitter) {
    return <ConnectTwitter connect />;
  }

  return (
    <Fragment>
      <TweetCard key={tweet.tweetId}>
        <TweetCard.Sidebar>
          <TweetCard.Image src={tweet.imageUrl} alt={tweet.name} />
          {tweet.conversation.length !== 0 && (
            <TweetCard.Line variant="pictonBlue" />
          )}
        </TweetCard.Sidebar>
        <TweetCard.Content>
          <TweetCard.Head>
            <TweetCard.TweetType>{tweet.tweetType}</TweetCard.TweetType>
            <TweetCard.Time>{renderCreatedAt()}</TweetCard.Time>
          </TweetCard.Head>
          <TweetCard.Bio>
            <Linkify>{tweet.text}</Linkify>
          </TweetCard.Bio>
          <TweetCard.Action>
            <RecentTweetActions
              data={tweet}
              onLikeClick={() => {
                if (isSignedIn) {
                  handleLike(tweet);
                } else {
                  setConnectTwitter(true);
                }
              }}
              onReplyClick={() => {
                if (isSignedIn) {
                  toggleTweetReplyCard();
                } else {
                  setConnectTwitter(true);
                }
              }}
              onRetweetClick={() => {
                if (isSignedIn) {
                  handleRetweet(tweet.tweetId);
                } else {
                  setConnectTwitter(true);
                }
              }}
            />
          </TweetCard.Action>
        </TweetCard.Content>
        <TweetCard.CWrapper>
          {tweet.conversation &&
            tweet.conversation.map((conversation) => (
              <RecentReplyCard reply={conversation} tweet={tweet} />
            ))}
        </TweetCard.CWrapper>
      </TweetCard>
      <TweetReplyCard
        tweetId={tweet.tweetId}
        tweetText={tweet.text}
        open={isTweetReplyCardOpen}
        onCancel={() => toggleTweetReplyCard(false)}
        files={files}
        onFilesChange={(files) => setFiles(files)}
        text={text}
        onTextChange={(value) => setText(value)}
        onSave={() => {
          handleReply({ tweetId: tweet.tweetId, files, text });
          setText("");
          setFiles([]);
          toggleTweetReplyCard(false);
        }}
      />
    </Fragment>
  );
};

export default RecentTweetCard;
