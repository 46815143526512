import { Icon } from "components/core";
import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const FilterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  svg {
    cursor: pointer;
  }
`;

const FormContainer = styled.div`
  max-height: 500px;
  width: 285px;
  background-color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.poppins};
  box-shadow: 1px 1px 3px 0 ${({ theme }) => theme.colors.extraLightGrey},
    -1px -1px 3px 0 ${({ theme }) => theme.colors.extraLightGrey};
  padding: ${helper.getSpacing("extraSmall")};
  position: absolute;
  top: 100%;
  right: 100%;
  z-index: 2;
  overflow: scroll;
  ${helper.getStyledScrollbar()}
`;

const CloseIcon = styled(Icon).attrs({
  name: "close",
})`
  &&& {
    position: absolute;
    right: 5%;
    top: 5%;
    cursor: pointer;
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colors.alpha};
  }
`;

export { FilterWrapper, FormContainer, CloseIcon };
