import { useState, Fragment } from "react";
import { Linkify } from "components/core";
import { useToggle } from "hooks";
import RecentReplyAction from "./RecentReplyAction";
import { TConversation, TRecentTweets } from "adapters/Twitter/types";
import TweetCard from "./TweetCard";
import TweetReplyCard from "../LeadGeneration/TweetReplyCard";
import { useRecentTweetContext } from "hooks/Twitter";
import { Container } from "./RecentReplyCard.styles";

type TRecentReplyCard = {
  reply: TConversation;
  tweet: TRecentTweets;
};

const RecentReplyCard = ({ reply, tweet }: TRecentReplyCard) => {
  const { handleReply, handleRetweet, handleReplyLikes } =
    useRecentTweetContext();

  const [isTweetReplyCardOpen, toggleTweetReplyCard] = useToggle();
  const [files, setFiles] = useState<File[]>([]);
  const [text, setText] = useState<string>("");

  return (
    <Fragment>
      <Container>
        <TweetCard.Image src={reply.imageUrl} alt={reply.name} />
        <TweetCard.Content>
          <TweetCard.Head>
            <TweetCard.Username
              name={reply.name}
              username={reply.leadUsername}
            />
          </TweetCard.Head>
          <TweetCard.Bio>
            <Linkify>{reply.text}</Linkify>
          </TweetCard.Bio>
          <TweetCard.Action>
            <RecentReplyAction
              data={reply}
              onLikeClick={() => handleReplyLikes(reply, tweet)}
              onReplyClick={toggleTweetReplyCard}
              onRetweetClick={() => handleRetweet(reply.tweetId)}
            />
          </TweetCard.Action>
        </TweetCard.Content>
      </Container>
      <TweetReplyCard
        tweetId={tweet.tweetId}
        tweetText={tweet.text}
        open={isTweetReplyCardOpen}
        onCancel={() => toggleTweetReplyCard(false)}
        files={files}
        onFilesChange={(files) => setFiles(files)}
        text={text}
        onTextChange={(value) => setText(value)}
        onSave={() => {
          handleReply({ tweetId: reply.tweetId, files, text });
          setText("");
          setFiles([]);
          toggleTweetReplyCard(false);
        }}
      />
    </Fragment>
  );
};

export default RecentReplyCard;
