import { useState } from "react";
import { Menu } from "semantic-ui-react";
import ThemeHelper from "theme/themeHelper";
import styled from "styled-components";
import NftTweetAnalytics from "./NftTweetAnalytics";
import NftInfluencer from "./NftInfluencer";
import NftCollaborator from "./NftCollaborator";

const helper = new ThemeHelper();

const Wrapper = styled.div`
  ${helper.containerize(85)};
`;

const NftMenu = () => {
  const [activeItem, setActiveItem] = useState("nft-tweet-analytics");

  const renderTableau = () => {
    if (activeItem === "nft-tweet-analytics") return <NftTweetAnalytics />;
    if (activeItem === "nft-influencers") return <NftInfluencer />;
    if (activeItem === "nft-collaborator") return <NftCollaborator />;
    else return null;
  };

  return (
    <Wrapper>
      <Menu attached="top" tabular>
        <Menu.Item
          name="nft-tweet-analytics"
          active={activeItem === "nft-tweet-analytics"}
          onClick={() => setActiveItem("nft-tweet-analytics")}
        />
        <Menu.Item
          name="nft-influencers"
          active={activeItem === "nft-influencers"}
          onClick={() => setActiveItem("nft-influencers")}
        />
        <Menu.Item
          name="Nft-collaborator"
          active={activeItem === "nft-collaborator"}
          onClick={() => setActiveItem("nft-collaborator")}
        />
        <Menu.Item
          name="more"
          active={activeItem === "more"}
          onClick={() => setActiveItem("more")}
        />
      </Menu>
      {renderTableau()}
    </Wrapper>
  );
};

export default NftMenu;
