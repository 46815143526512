import { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { Formik } from "formik";
import { IconButton } from "components/Twitter/common";
import { ExcelIcon } from "../../common/icons";
import { useOnClickOutside } from "hooks";
import { IReportsGenerationFormValues } from "context/Twitter/types";
import { useLeads } from "hooks/Twitter";
import { Button, Flex, Form } from "components/core";
import {
  ExportMenuWrapper,
  PreviewWrapper,
} from "components/Twitter/common/Common.styles";

const CustomerExportMenu = () => {
  const [showPreview, setShowPreview] = useState(false);

  const { handleReportsGeneration } = useLeads();

  const onOutSideClick = () => {
    setShowPreview(false);
  };

  const downloadSubmitHandler = ({
    socialInfo,
  }: IReportsGenerationFormValues) => {
    handleReportsGeneration({ socialInfo });
  };

  const previewRef = useOnClickOutside<HTMLDivElement>(onOutSideClick);

  const renderPreview = () => {
    if (showPreview) {
      return (
        <PreviewWrapper ref={previewRef}>
          <Formik
            initialValues={{ socialInfo: false }}
            onSubmit={downloadSubmitHandler}
          >
            {({ handleSubmit }) => (
              <Form.Form onSubmit={handleSubmit}>
                <Form.Field>
                  <Form.Checkbox
                    name="socialInfo"
                    id="socialInfo"
                    label={
                      <Form.Label
                        style={{
                          textAlign: "center",
                          display: "inline",
                          cursor: "pointer",
                        }}
                        htmlFor="socialInfo"
                      >
                        Social Info
                      </Form.Label>
                    }
                  />
                </Form.Field>
                <Flex justify="end">
                  <Button
                    variant="alpha"
                    content="Download"
                    size="tiny"
                    type="submit"
                  />
                </Flex>
              </Form.Form>
            )}
          </Formik>
        </PreviewWrapper>
      );
    }
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <ExportMenuWrapper>
      <IconButton
        icon={ExcelIcon}
        data-tip="Download Potential Customer List as Excel"
        onClick={() => setShowPreview(true)}
      />
      {renderPreview()}
    </ExportMenuWrapper>
  );
};

export default CustomerExportMenu;
