import { useTwitterAuth } from "hooks";
import { useScheduleRetweet } from "hooks/Twitter";
import { ConnectTwitter } from "../common";
import ScheduleRetweetCard from "./ScheduleRetweetCard";
import { ScheduleTweetListContainer } from "./ScheduleTweetList.styles";

const ScheduleTweetList = () => {
  const { retweetCards, tweetActions } = useScheduleRetweet();
  const { isSignedIn } = useTwitterAuth();

  if (!tweetActions && !isSignedIn) {
    return <ConnectTwitter connect />;
  }

  return (
    <ScheduleTweetListContainer>
      {retweetCards.map((retweet) => (
        <ScheduleRetweetCard key={retweet.id} retweet={retweet} />
      ))}
    </ScheduleTweetListContainer>
  );
};

export default ScheduleTweetList;
