import { useEffect } from "react";
import { usePendo } from "hooks/common";
import { AutoWelcome } from "components/Twitter";
import { AutoDmProvider } from "context/Twitter";

const AutoDm = () => {
  const { initializePendo } = usePendo();

  useEffect(() => {
    initializePendo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AutoDmProvider>
      <AutoWelcome />
    </AutoDmProvider>
  );
};

export default AutoDm;
