import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 2px 2px 4px 0 ${({ theme }) => theme.colors.extraLightGrey},
    -2px -2px 4px 0 ${({ theme }) => theme.colors.extraLightGrey};
  padding: 0 ${helper.getSpacing("small")};
  width: 32vw;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 10%;
  z-index: 10;

  @media screen and (max-width: 1024px) {
    width: 40vw;
  }

  @media screen and (max-width: 768px) {
    width: 64vw;
  }

  @media screen and (max-width: 425px) {
    width: 96vw;
  }
`;

export { Container };
