/* Libs */
import { lazy, Suspense } from "react";
import { Switch } from "react-router";
/* Layouts */
import { DolphynAppsLayout } from "layout";
import { TopLogoLayout } from "layout/common";
import { ProfileLayout } from "layout/Profile";
/* Context */
import { PreferencesProvider } from "context/ProductDemand";
// import { AllBlogProvider, SingleBlogProvider } from "context/Blog";
/* Config */
import { applicationRoutes as routes } from "config/common";
/* Components */
import { ErrorPage, Spinner } from "components/common";
/* Controllers */
import { SimpleRoute } from "controllers/common";
import { AdminRoute, ProtectedRoute, PublicRoute } from "controllers/Auth";
/* Sub routes */
import { AuthLayout } from "layout/Auth";
import { ProfileRoutes } from "./Profile";
import { DashboardRoutes } from "./Dashboard";
import { SocialAccountRoutes } from "./SocialAccount";
import { SocialMediaRoutes } from "./SocialMediaPost";
import { SubHeadingProvider } from "context/common";
import { PricingRoutes } from "./Pricing";
import { ProductDemandRoutes } from "./ProductDemand";
import { ContentCreationRoutes } from "./ContentCreation";
import { TwitterRoutes } from "./Twitter";

// Pages
import { NFTPage } from "pages/NFT";

// Code Splitting Pages
const Pricing = lazy(() => import("pages/Pricing/Pricing"));
const Home = lazy(() => import("pages/Home/Home"));
const Admin = lazy(() => import("pages/Admin/Admin"));
const Signin = lazy(() => import("pages/Auth/Signin"));
const Signup = lazy(() => import("pages/Auth/Signup"));
const LoginEnterprise = lazy(() => import("pages/Auth/LoginEnterprise"));
const ResetPassword = lazy(() => import("pages/Auth/ResetPassword"));
const ForgotPassword = lazy(() => import("pages/Auth/ForgotPassword"));

const RootRoutes = () => {
  return (
    <Suspense fallback={<Spinner fullscreen />}>
      <Switch>
        <PublicRoute layout={AuthLayout} exact path={routes.rootRoutes.signIn}>
          <Signin />
        </PublicRoute>
        <PublicRoute layout={AuthLayout} exact path={routes.rootRoutes.signUp}>
          <Signup />
        </PublicRoute>
        <PublicRoute
          layout={AuthLayout}
          exact
          path={routes.rootRoutes.loginEnterprise}
        >
          <LoginEnterprise />
        </PublicRoute>
        <PublicRoute
          layout={AuthLayout}
          exact
          path={routes.rootRoutes.resetPassword}
        >
          <ResetPassword />
        </PublicRoute>
        <PublicRoute
          layout={AuthLayout}
          exact
          path={routes.rootRoutes.forgotPassword}
        >
          <ForgotPassword />
        </PublicRoute>
        <PublicRoute exact path={routes.rootRoutes.home}>
          <Home />
        </PublicRoute>
        <PublicRoute exact path={routes.rootRoutes.pricing}>
          <Pricing />
        </PublicRoute>
        <PublicRoute exact path={routes.rootRoutes.nft}>
          <NFTPage />
        </PublicRoute>
        <ProtectedRoute layout={ProfileLayout} path={routes.rootRoutes.profile}>
          <ProfileRoutes />
        </ProtectedRoute>
        <SimpleRoute
          layout={DolphynAppsLayout}
          path={routes.rootRoutes.pricing}
        >
          <PricingRoutes />
        </SimpleRoute>
        <AdminRoute
          layout={DolphynAppsLayout}
          path={routes.rootRoutes.adminMail}
        >
          <Admin />
        </AdminRoute>
        <ProtectedRoute
          layout={DolphynAppsLayout}
          path={routes.rootRoutes.dashboard}
        >
          <DashboardRoutes />
        </ProtectedRoute>
        <ProtectedRoute
          layout={DolphynAppsLayout}
          path={routes.rootRoutes.contentCreation}
        >
          <ContentCreationRoutes />
        </ProtectedRoute>
        <ProtectedRoute
          layout={DolphynAppsLayout}
          path={routes.rootRoutes.socialAccount}
        >
          <SocialAccountRoutes />
        </ProtectedRoute>
        <ProtectedRoute
          layout={DolphynAppsLayout}
          path={routes.rootRoutes.productDemand}
        >
          <PreferencesProvider>
            <ProductDemandRoutes />
          </PreferencesProvider>
        </ProtectedRoute>
        <ProtectedRoute
          layout={DolphynAppsLayout}
          path={routes.rootRoutes.easyTwitter}
        >
          <TwitterRoutes />
        </ProtectedRoute>
        <ProtectedRoute
          layout={DolphynAppsLayout}
          path={routes.rootRoutes.socialMediaPost}
        >
          <SubHeadingProvider>
            <SocialMediaRoutes />
          </SubHeadingProvider>
        </ProtectedRoute>
        <PublicRoute layout={TopLogoLayout}>
          <ErrorPage
            icon=""
            buttonText=""
            variant="primary"
            onClick={() => {}}
            message="Page not found!"
            button
            redirectPath={routes.rootRoutes.dashboard}
          />
        </PublicRoute>
      </Switch>
    </Suspense>
  );
};

export default RootRoutes;
