import styled from "styled-components";

const ListContainer = styled.div`
  .my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -0.5rem; /* gutter size offset */
    width: auto;
  }
  .my-masonry-grid_column {
    padding-left: 0.5rem; /* gutter size */
    background-clip: padding-box;
  }

  /* Style your items */
  .my-masonry-grid_column > div {
    /* change div to reference your elements you put in <Masonry> */
    margin-bottom: 0.5rem;
  }
`;

export { ListContainer };
