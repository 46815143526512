import { useLeads } from "hooks/Twitter";
import { useAppDispatch, useAppSelector } from "redux/store/hooks";
import { Item, Menu } from "./CustomerSortBy.styles";
import {
  setMostFollowers,
  setMostFollowing,
  setMostTweets,
} from "redux/slices/Twitter";

const CustomerSortBy = () => {
  const { handleFilter } = useLeads();
  const dispatch = useAppDispatch();
  const mostTweets = useAppSelector(
    (state) => state.twitter.potentialCustomer.mostTweets
  );
  const mostFollowers = useAppSelector(
    (state) => state.twitter.potentialCustomer.mostFollowers
  );
  const mostFollowing = useAppSelector(
    (state) => state.twitter.potentialCustomer.mostFollowing
  );

  return (
    <Menu text>
      <Item
        name="Most Tweets"
        active={mostTweets}
        onClick={() => {
          handleFilter("tweets");
          dispatch(setMostTweets());
        }}
      />
      <Item
        name="Most Followers"
        active={mostFollowers}
        onClick={() => {
          handleFilter("followers");
          dispatch(setMostFollowers());
        }}
      />
      <Item
        name="Most Following"
        active={mostFollowing}
        onClick={() => {
          handleFilter("following");
          dispatch(setMostFollowing());
        }}
      />
    </Menu>
  );
};

export default CustomerSortBy;
