import styled from "styled-components";

const CardContainer = styled.div`
  width: 374px;
  padding: 12px;
  margin-top: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.extraLightGrey};
  border-radius: 8px;
`;

const UserPicture = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
`;

const Content = styled.div`
  padding: 0.5rem 0;
  white-space: pre-line;
  font-family: ${({ theme }) => theme.fonts.poppins};
  color: ${({ theme }) => theme.colors.darkGrey};
  font-size: 13px;
`;

const BottomSection = styled.div`
  padding: 0.5rem 0;
  display: flex;
  gap: 1.5rem;
  align-items: center;
`;

const TopSection = styled.div`
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
`;

export { CardContainer, UserPicture, TopSection, BottomSection, Content };
