import { DateTimePicker } from "components/common";
import { Flex, Form } from "components/core";
import { IAdvancedSearchFormValues } from "context/Twitter/types";
import { useField } from "formik";

interface IAdvancedSearcDates {
  startDate: string;
  endDate: string;
}

const AdvancedSearchDate = (props: IAdvancedSearcDates) => {
  const TODAY = new Date();
  const YESTERDAY = new Date(Date.now() - 86400000);
  const [start, , startHelper] = useField<
    IAdvancedSearchFormValues["startDate"]
  >(props.startDate);
  const [end, , endHelper] = useField<IAdvancedSearchFormValues["endDate"]>(
    props.endDate
  );

  return (
    <Flex font="poppins">
      <Form.Field>
        <Form.Label content="Start Date" />
        <DateTimePicker.DatePicker
          value={start.value}
          onChange={(value) => startHelper.setValue(value)}
          maxDate={YESTERDAY}
          style={{ boxShadow: "unset", height: "40px" }}
        />
      </Form.Field>
      <Form.Field>
        <Form.Label content="End Date" />
        <DateTimePicker.DatePicker
          value={end.value}
          onChange={(value) => endHelper.setValue(value)}
          maxDate={TODAY}
          style={{ boxShadow: "unset", height: "40px" }}
        />
      </Form.Field>
    </Flex>
  );
};

export default AdvancedSearchDate;
