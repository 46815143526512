import { Text } from "components/core";
import {
  Action,
  ScheduleTimeContainer,
  UserContainer,
  Container,
  Image,
  CriteriaText,
} from "./ScheduleTweet.styles";
import { scheduleRetweetStatus } from "config/Twitter";
import { relativeTime } from "helpers";
interface ICriteria {
  criteria: string;
}

const Criteria = ({ criteria }: ICriteria) => {
  return <CriteriaText>{criteria}</CriteriaText>;
};

interface IStatusProps {
  status: string;
}

const Status = ({ status }: IStatusProps) => {
  if (status === scheduleRetweetStatus.scheduled)
    return <Text size="extraSmall" variant="goldFoil" content="Scheduled" />;
  return <Text size="extraSmall" variant="alpha" content="Posted" />;
};

interface IUserProps {
  image: string;
  name: string;
}

const User = ({ image, name }: IUserProps) => {
  return (
    <UserContainer>
      <Image src={image} alt={name} />
      <Text style={{ fontSize: "14px" }} variant="black" content={name} />
    </UserContainer>
  );
};

interface IScheduleTimeProps {
  scheduleTime: string;
}

const ScheduleTime = ({ scheduleTime }: IScheduleTimeProps) => {
  return (
    <ScheduleTimeContainer>
      <Text size="extraSmall" content={relativeTime(scheduleTime)} />
    </ScheduleTimeContainer>
  );
};

interface IScheduleTweetProps {
  children?: React.ReactNode;
  active?: boolean;
}
const ScheduleTweet = ({ children, active }: IScheduleTweetProps) => {
  if (active) return <Container active>{children}</Container>;
  return <Container active={false}>{children}</Container>;
};

ScheduleTweet.Criteria = Criteria;
ScheduleTweet.ScheduleTime = ScheduleTime;
ScheduleTweet.User = User;
ScheduleTweet.Status = Status;
ScheduleTweet.Action = Action;

export default ScheduleTweet;
