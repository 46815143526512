import { useEffect, useState } from "react";
import { Formik } from "formik";
import { useBoolean } from "hooks/common";
import { useAdminEngagement } from "hooks/Twitter";
import { Text, Toggle, Button, Flex, Modal } from "components/core";
import { topParagraph } from "config/Twitter";
import KeywordForm from "./KeywordForm";
import SelectMatchNumber from "./SelectMatchNumber";
import ModalMessageCard from "./ModalMessageCard";
import { IAdminEngagementFormValues } from "context/Twitter/types";
import { IAdminEngagementResult } from "adapters/Twitter/types";
import ReplyCard from "./ReplyCard";
import FormActions from "./FormActions";
import { getKeywordsLength, parseKeywords } from "helpers";

interface CardProps {
  tweet: IAdminEngagementResult;
  keywords: string[];
  isReplying: boolean;
  setIsReplying: (value?: boolean) => void;
  setReplyText: (value: string) => void;
  setKeywords: (values: string[]) => void;
  setMatchNumber: (value: number) => void;
  setIsLiked: (value?: boolean) => void;
}

const FormCard = ({
  tweet,
  setIsReplying,
  setReplyText,
  setKeywords,
  setMatchNumber,
  keywords,
  setIsLiked,
  isReplying,
}: CardProps) => {
  const engagement = useAdminEngagement();

  const [checked, setChecked] = useState(tweet.enable);
  const [showModal, setShowModalTrue, setShowModalFalse] = useBoolean();

  const initialState: IAdminEngagementFormValues = {
    keywords: parseKeywords(engagement.data.keywords),
    matchNumber: engagement.data.matchNumber,
    like: false,
    reply: false,
    replyText: tweet.replyText || "",
    files: [],
  };

  const handleFormikSubmit = ({
    keywords,
    matchNumber,
    like,
    reply,
    replyText,
    files,
  }: IAdminEngagementFormValues) => {
    engagement.handleCreate({
      keywords,
      matchNumber,
      like,
      reply,
      replyText,
      files,
    });
  };

  const handleToggle = () => {
    setChecked(true);
    if (checked) {
      setShowModalTrue();
    }
  };

  const handleDelete = () => {
    engagement.handleDelete();
    setChecked(false);
    setShowModalFalse();
  };

  useEffect(() => {
    setChecked(engagement.data.enable);
  }, [engagement.data]);

  return (
    <>
      <Text>{topParagraph}</Text>
      <Toggle
        checked={checked}
        onClick={handleToggle}
        style={{ alignSelf: "end", marginRight: "1rem" }}
      />
      <Modal
        open={showModal}
        onOutsideClick={setShowModalFalse}
        onClose={setShowModalFalse}
      >
        <ModalMessageCard onDelete={handleDelete} />
      </Modal>
      <Formik
        initialValues={initialState}
        enableReinitialize
        onSubmit={handleFormikSubmit}
      >
        {(formik) => (
          <>
            <KeywordForm name="keywords" setKeywords={setKeywords} />
            <div>
              <Text as="span">Out of your </Text>
              <Text as="span" size="extraSmall" variant="alpha">
                {getKeywordsLength(keywords)}
              </Text>
              <Text as="span">
                {" "}
                words if potential customers new tweet matches
              </Text>
            </div>
            <SelectMatchNumber
              name="matchNumber"
              setMatchNumber={setMatchNumber}
            />
            <Text>Words, then Dolphy will</Text>
            <FormActions
              name={{
                like: "like",
                reply: "reply",
              }}
              onLike={() => setIsLiked()}
              onReply={() => setIsReplying()}
            />
            <ReplyCard
              open={isReplying}
              onCancel={() => setIsReplying()}
              setReplyText={setReplyText}
              name={{ text: "replyText", files: "files" }}
            />
            <Flex>
              <Button
                variant="alpha"
                content="Apply"
                type="submit"
                onClick={() => {
                  formik.handleSubmit();
                  setIsReplying(false);
                }}
              />
            </Flex>
          </>
        )}
      </Formik>
    </>
  );
};

export default FormCard;
