import { useEffect } from "react";
import {
  InfluencersListProvider,
  ScheduleRetweetProvider,
} from "context/Twitter";
import { usePendo } from "hooks/common";
import {
  ScheduleTweetList,
  ScheduleRetweetForm,
  ScheduleSortBy,
} from "components/Twitter/ScheduleRetweet";

const ScheduleRetweet = () => {
  const { initializePendo } = usePendo();

  useEffect(() => {
    initializePendo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ScheduleRetweetProvider>
      <ScheduleSortBy />
      <InfluencersListProvider>
        <ScheduleRetweetForm />
      </InfluencersListProvider>
      <ScheduleTweetList />
    </ScheduleRetweetProvider>
  );
};

export default ScheduleRetweet;
