import { useState } from "react";
import { useField } from "formik";
import { useKeywordInputArray } from "hooks/common";
import { Flex, KeywordInput, LinkButton } from "components/core";
import { TEngagementCreateFormValues } from "context/Twitter/types";

interface IEngagementKeywordFormProps {
  name: string;
  setKeywords: (values: string[]) => void;
}

const KeywordForm = ({ name, setKeywords }: IEngagementKeywordFormProps) => {
  const [keywords, , keywordsHelper] =
    useField<TEngagementCreateFormValues["keywords"]>(name);

  const [inputArrayLength, setInputArrayLength] = useState(5);

  const [, , keywordsArray] = useKeywordInputArray(10);

  const handleInputArrayLength = () => {
    setInputArrayLength((prevState) => prevState + 1);
  };

  return (
    <>
      <KeywordInput>
        <KeywordInput.InputArray
          value={keywords.value}
          numberingOffset={0}
          onChange={(e, index) => {
            const value = keywordsArray.getChangedKeywordsArray(
              keywords.value,
              index,
              e.target.value
            );
            keywordsHelper.setValue(
              value as TEngagementCreateFormValues["keywords"]
            );
            setKeywords(value);
          }}
          length={inputArrayLength}
        />
      </KeywordInput>
      <Flex>
        <LinkButton content="Add more" onClick={handleInputArrayLength} />
      </Flex>
    </>
  );
};

export default KeywordForm;
