import { useState } from "react";
import { TScheduleRetweet } from "adapters/Twitter/types";
import { IconButton } from "components/core";
import { useScheduleRetweet } from "hooks/Twitter";
import ScheduleTweet from "./ScheduleTweet";
import UpdateForm from "./UpdateForm";
import { EditIcon, RemoveIcon, CloseIcon, TwitterIcon } from "../common/icons";

interface ScheduleRetweetCardProps {
  retweet: TScheduleRetweet;
}

const ScheduleRetweetCard = ({ retweet }: ScheduleRetweetCardProps) => {
  const scheduleRetweets = useScheduleRetweet();

  const [edit, setEdit] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const handleEdit = () => {
    setEdit(true);
    setShowUpdateForm(true);
  };

  return (
    <>
      <ScheduleTweet key={retweet.id} active={edit}>
        <ScheduleTweet.Criteria criteria={retweet.criteria} />
        <ScheduleTweet.ScheduleTime scheduleTime={retweet.scheduleTime} />
        <ScheduleTweet.User
          name={retweet.influencer}
          image={retweet.profileImage}
        />
        <ScheduleTweet.Status status={retweet.status} />
        <ScheduleTweet.Action>
          <IconButton
            variant="pictonBlue"
            icon={edit ? CloseIcon : EditIcon}
            onClick={handleEdit}
          />
          <IconButton
            variant="danger"
            icon={RemoveIcon}
            onClick={() => scheduleRetweets.handleDelete({ id: retweet.id })}
          />
          {retweet.searchTweet && (
            <IconButton
              variant="transparent"
              icon={TwitterIcon}
              onClick={() => window.open(retweet.tweetUrl, "_blank")}
            />
          )}
        </ScheduleTweet.Action>
      </ScheduleTweet>
      <UpdateForm
        open={showUpdateForm}
        onClose={() => {
          setShowUpdateForm(false);
          setEdit(false);
        }}
        formValues={{
          id: retweet.id,
          highest_liked: retweet.criteria === "Highest Liked" ? true : false,
          datetime: new Date(retweet.scheduleTime),
        }}
        searchTweet={retweet.searchTweet}
        onSubmit={(values) => {
          scheduleRetweets.handleUpdate(values);
          setShowUpdateForm(false);
          setEdit(false);
        }}
      />
    </>
  );
};

export default ScheduleRetweetCard;
