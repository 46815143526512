import { Header } from "components/core";
import { useBoolean, useOnClickOutside } from "hooks";
import { useRecentTweetRedux } from "hooks/Twitter";
import { useEffect } from "react";
import RecentTweetCard from "./RecentTweetCard";
import { ListWrapper, TweetList } from "./RecentTweetList.styles";

const variants = {
  open: { y: 0, display: "flex" },
  close: { y: "100%", display: "none" },
};

const RecentTweetList = () => {
  const { data: tweets } = useRecentTweetRedux();

  const [open, onOpen, onClose] = useBoolean();

  const listRef = useOnClickOutside<HTMLDivElement>(onClose);

  useEffect(() => {
    if (tweets.length) onOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tweets]);

  return (
    <ListWrapper
      ref={listRef}
      animate={open ? "open" : "close"}
      variants={variants}
      transition={{ duration: 0.5 }}
    >
      <Header
        content="Last 7 Days Tweets"
        variant="alpha"
        font="poppins"
        textAlign="center"
        fontWeight="medium"
      />
      <TweetList>
        {tweets.map((tweet) => (
          <RecentTweetCard key={tweet.tweetId} tweet={tweet} />
        ))}
      </TweetList>
    </ListWrapper>
  );
};

export default RecentTweetList;
