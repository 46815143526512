import { useTwitterAuth } from "hooks";
import { useKeywordListening } from "hooks/Twitter";
import { useCallback, useEffect, useRef } from "react";
import Masonry from "react-masonry-css";
import { v4 as uuid } from "uuid";
import { ConnectTwitter } from "../common";
import TweetSearchCard from "./TweetSearchCard";
import { Container } from "./TweetSearchList.styles";
import { useMediaQuery } from "@mui/material";

const TweetSearchList = () => {
  const loader = useRef(null);
  const isMound = useRef(false);
  const { isTwitterConnected, handleLoadMore, cards } = useKeywordListening();
  const { isSignedIn } = useTwitterAuth();
  const isMobileScreen = useMediaQuery("(max-width: 425px)");
  const isTabletScreen = useMediaQuery("(max-width: 768px)");

  const handleObserver = useCallback((entries: any[]) => {
    const target = entries[0];
    if (target.isIntersecting) {
      handleLoadMore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isMound.current) {
      const option = {
        root: null,
        rootMargin: "0px",
        threshold: 0.5,
      };
      const observer = new IntersectionObserver(handleObserver, option);
      if (loader.current) observer.observe(loader.current);
    } else {
      isMound.current = true;
    }
  }, [handleObserver]);

  if (isTwitterConnected && !isSignedIn) {
    return <ConnectTwitter connect={isTwitterConnected} />;
  }

  return (
    <Container>
      <Masonry
        breakpointCols={isMobileScreen ? 1 : isTabletScreen ? 2 : 3}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {cards.map((tweet) => (
          <TweetSearchCard tweet={tweet} key={uuid()} />
        ))}
      </Masonry>
      <div ref={loader} />
    </Container>
  );
};

export default TweetSearchList;
