import { useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { Button, Flex, Form, IconButton } from "components/core";
import { useInfluencers } from "hooks/Twitter";
import { setShowSetLimit } from "redux/slices/Twitter";
import { useAppDispatch, useAppSelector } from "redux/store/hooks";
import { FormContainer, SetLimitWrapper } from "./SetLimit.styles";
import { UsersIcon } from "components/Twitter/common/icons";
import { useOnClickOutside } from "hooks";
import { CloseIcon } from "components/Twitter/common/Common.styles";
import { Formik } from "formik";
import { IFollowersLimitFormValues } from "context/Twitter/types";

const SetLimit = () => {
  const dispatch = useAppDispatch();
  const showSetLimit = useAppSelector(
    (state) => state.twitter.influencer.showSetLimit
  );
  const initialLimit = useAppSelector(
    (state) => state.twitter.influencer.followersLimit
  );
  const { handleFollowersLimit } = useInfluencers();

  const handleOutsideClick = () => {
    dispatch(setShowSetLimit(false));
  };

  const handleFormikSubmit = ({
    followersLimit,
  }: IFollowersLimitFormValues) => {
    handleFollowersLimit({ followersLimit });
    dispatch(setShowSetLimit(false));
  };

  const renderSetLimitRef =
    useOnClickOutside<HTMLDivElement>(handleOutsideClick);

  const renderSetLimit = () => {
    if (showSetLimit) {
      return (
        <FormContainer ref={renderSetLimitRef}>
          <CloseIcon onClick={handleOutsideClick} />
          <Formik
            initialValues={{ followersLimit: initialLimit }}
            onSubmit={handleFormikSubmit}
          >
            {({ handleSubmit }) => (
              <Form.Form onSubmit={handleSubmit}>
                <Form.Field>
                  <Form.Label content="Set Followers Limit" />
                  <Form.Input
                    type="number"
                    name="followersLimit"
                    focused
                    style={{ boxShadow: "unset", height: "40px" }}
                  />
                </Form.Field>
                <Flex justify="end">
                  <Button
                    variant="alpha"
                    content="Set Limit"
                    mt="small"
                    type="submit"
                  />
                </Flex>
              </Form.Form>
            )}
          </Formik>
        </FormContainer>
      );
    }
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <SetLimitWrapper>
      <IconButton
        variant="transparent"
        icon={UsersIcon}
        data-tip="Set Followers Limit"
        onClick={() => dispatch(setShowSetLimit(true))}
      />
      {renderSetLimit()}
    </SetLimitWrapper>
  );
};

export default SetLimit;
