import { useEffect, useRef } from "react";
import styled from "styled-components";
declare const window: any;

const Dashboard = styled.div`
  margin: 0 auto;
`;

const URL =
  "https://public.tableau.com/views/NFTCollaborator/NFTCollaborator?:language=en-US&:display_count=n&:origin=viz_share_link&:device=desktop";

const NftCollaborator = () => {
  const { tableau } = window;
  const ref = useRef(null);

  useEffect(() => {
    const initViz = () => {
      new tableau.Viz(ref.current, URL);
    };

    initViz();
  }, [tableau]);

  return <Dashboard ref={ref} />;
};

export default NftCollaborator;
