import { useScheduleRetweet } from "hooks/Twitter";
import { setPostedRetweets, setScheduledRetweets } from "redux/slices/Twitter";
import { useAppDispatch, useAppSelector } from "redux/store/hooks";
import { Menu, Item, SortByWrapper } from "./SortBy.styles";

const ScheduleSortBy = () => {
  const { handleFilter } = useScheduleRetweet();

  const dispatch = useAppDispatch();
  const posted = useAppSelector(
    (state) => state.twitter.scheduleRetweets.posted
  );
  const scheduled = useAppSelector(
    (state) => state.twitter.scheduleRetweets.scheduled
  );

  return (
    <SortByWrapper>
      <Menu text>
        <Item
          name="posted"
          active={posted}
          onClick={() => {
            handleFilter("RETWEETED");
            dispatch(setPostedRetweets());
          }}
        />
        <Item
          name="scheduled"
          active={scheduled}
          onClick={() => {
            handleFilter("SCHEDULED");
            dispatch(setScheduledRetweets());
          }}
        />
      </Menu>
    </SortByWrapper>
  );
};

export default ScheduleSortBy;
