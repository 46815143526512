import styled, { css, DefaultColors } from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

interface ILineProps {
  variant: keyof DefaultColors;
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 15fr;
  column-gap: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.extraLightGrey};
  border-radius: 10px;
  padding: 1rem;
`;

const Image = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex-grow: 1;
`;

const Head = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const Bio = styled.p`
  font-family: ${({ theme }) => theme.fonts.poppins};
  font-size: 14px;
  margin: 0;
  color: ${({ theme }) => theme.colors.lightGrey};
`;

const Action = styled.div`
  display: flex;
  gap: ${helper.getSpacing("small")};
  flex-wrap: wrap;
`;

const Time = styled.div`
  font-family: ${({ theme }) => theme.fonts.poppins};
  color: ${({ theme }) => theme.colors.alpha};
  font-size: 14px;
  cursor: pointer;
`;

const TweetType = styled.div`
  font-family: ${({ theme }) => theme.fonts.poppins};
  color: ${({ theme }) => theme.colors.black};
  font-size: 14px;
`;

const CWrapper = styled.div`
  grid-column-start: 1;
  grid-column-end: 4;
`;

const Line = styled.div<ILineProps>`
  margin-top: ${helper.getSpacing("tiny")};
  width: 2px;
  height: 100%;
  margin-right: auto;
  margin-left: auto;
  ${({ variant, theme }) => {
    const { colors } = theme;
    return css`
      background-color: ${colors[variant]};
    `;
  }}
`;

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
`;

export {
  Container,
  Action,
  Time,
  Bio,
  Content,
  Head,
  Image,
  TweetType,
  CWrapper,
  Line,
  Sidebar,
};
