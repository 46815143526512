import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const SortByWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const SortByPreview = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.poppins};
  box-shadow: 1px 1px 3px 0 ${({ theme }) => theme.colors.extraLightGrey},
    -1px -1px 3px 0 ${({ theme }) => theme.colors.extraLightGrey};
  padding: ${helper.getSpacing("medium")};
  position: absolute;
  top: 100%;
  right: 100%;
  z-index: 2;
`;

export { SortByWrapper, SortByPreview };
