import { useEffect } from "react";
import { usePendo } from "hooks/common";
import { Flex } from "components/core";
import { useAppSelector } from "redux/store/hooks";
import { InfluencersProvider, RecentTweetsProvider } from "context/Twitter";
import {
  InfluencerDropTarget,
  InfluencerList,
} from "components/Twitter/ScheduleRetweet";
import { RecentTweets } from "components/Twitter/RecentTweets";
import {
  ExportMenu,
  InfluencerFilter,
  InfluencerSortBy,
  SetLimit,
} from "components/Twitter/ScheduleRetweet/Influencer";
import { ConnectTwitter } from "components/Twitter/common";

const Influencers = () => {
  const { initializePendo } = usePendo();

  const connectTwitter = useAppSelector(
    (state) => state.twitter.common.connectTwitter
  );

  useEffect(() => {
    initializePendo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <InfluencersProvider>
      <Flex justify="end">
        <SetLimit />
        <ExportMenu />
        <InfluencerSortBy />
        <InfluencerFilter />
      </Flex>
      <RecentTweetsProvider>
        <RecentTweets />
      </RecentTweetsProvider>
      {connectTwitter && <ConnectTwitter connect />}
      <InfluencerDropTarget />
      <InfluencerList />
    </InfluencersProvider>
  );
};

export default Influencers;
