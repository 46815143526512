import styled from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const ScheduleRetweetFormContainer = styled.div`
  padding-inline-end: ${helper.getSpacing("extraLarge")};
  padding-inline-start: ${helper.getSpacing("extraLarge")};
  display: grid;
  grid-template-columns: 5fr 3fr 3fr 1fr;
  column-gap: ${helper.getSpacing("small")};
`;

export { ScheduleRetweetFormContainer };
