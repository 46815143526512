import { EmojiPicker, FileAttacher, Textbox } from "components/common";
import { Button, Flex, LinkButton } from "components/core";
import { socialMediaCharactersLimit } from "config/common";
import { textKeys } from "config/Twitter";
import { IAdminEngagementFormValues } from "context/Twitter/types";
import { useField } from "formik";
import { useToggle } from "hooks";
import { useTextHelper } from "hooks/Twitter";
import { useEffect, useState } from "react";
import { MessageEditor } from "../../common";
import { Toolbar } from "../TweetReplyCard.styles";

interface IReplyCardProps {
  name: {
    text: string;
    files: string;
  };
  open: boolean;
  onCancel: () => void;
  setReplyText: (value: string) => void;
}

const ReplyCard = (props: IReplyCardProps) => {
  const [textField, , textHelpers] = useField<
    IAdminEngagementFormValues["replyText"]
  >(props.name.text);

  const [, , fileHelpers] = useField<IAdminEngagementFormValues["files"]>(
    props.name.files
  );

  const { text1Message, text2Message, setTextMessage } = useTextHelper();

  const [editorText1, setEditorText1] = useState(text1Message);
  const [editorText2, setEditorText2] = useState(text2Message);
  const [openEditor1, toggleEditor1] = useToggle();
  const [openEditor2, toggleEditor2] = useToggle();

  useEffect(() => {
    setEditorText1(text1Message);
    setEditorText2(text2Message);
  }, [text1Message, text2Message]);

  if (!props.open) return null;

  return (
    <Textbox
      placeholder="Enter reply here..."
      value={textField.value}
      onChange={(e) => {
        if (e.target.value.length - 1 < socialMediaCharactersLimit.twitter) {
          textHelpers.setValue(e.target.value);
          props.setReplyText(e.target.value);
        }
      }}
      style={{ position: "relative" }}
    >
      <MessageEditor
        open={openEditor1}
        text={editorText1}
        onCancel={() => toggleEditor1(false)}
        onTextChange={(value) => setEditorText1(value)}
        onSave={() => {
          setTextMessage(textKeys.Text1, editorText1);
          toggleEditor1(false);
        }}
      />
      <MessageEditor
        open={openEditor2}
        text={editorText2}
        onCancel={() => toggleEditor2(false)}
        onTextChange={(value) => setEditorText2(value)}
        onSave={() => {
          setTextMessage(textKeys.Text2, editorText2);
          toggleEditor2(false);
        }}
      />
      <Textbox.WordLimit
        remaining={socialMediaCharactersLimit.twitter - textField.value.length}
      />
      <Textbox.Toolbar
        style={{ justifyContent: "space-between", background: "unset" }}
      >
        <Toolbar>
          <Flex gap="none">
            <Button
              variant="alpha"
              content="Text1"
              size="tiny"
              onClick={() => {
                textHelpers.setValue(textField.value + text1Message);
                props.setReplyText(textField.value + text1Message);
              }}
            />
            <Button
              icon="edit"
              variant="alpha"
              size="tiny"
              onClick={() => toggleEditor1(true)}
            />
          </Flex>
          <Flex gap="none">
            <Button
              variant="alpha"
              content="Text2"
              size="tiny"
              onClick={() => {
                textHelpers.setValue(textField.value + text2Message);
                props.setReplyText(textField.value + text2Message);
              }}
            />
            <Button
              icon="edit"
              variant="alpha"
              size="tiny"
              onClick={() => toggleEditor2(true)}
            />
          </Flex>
          <EmojiPicker
            onSelect={(emoji) => textHelpers.setValue(textField.value + emoji)}
          />
          <FileAttacher onChange={fileHelpers.setValue} />
        </Toolbar>
        <Toolbar>
          <LinkButton content="Cancel" onClick={props.onCancel} />
        </Toolbar>
      </Textbox.Toolbar>
    </Textbox>
  );
};

export default ReplyCard;
