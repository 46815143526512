import styled, { css } from "styled-components";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

interface IContainer {
  active: boolean;
}

const Container = styled.div<IContainer>`
  border-radius: ${helper.getSpacing("extraSmall")};
  padding: ${helper.getSpacing("tiny")};
  display: grid;
  grid-template-columns: 1.2fr 1.5fr 2fr 1fr 1fr;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.extraLightGrey};
  ${({ active, theme }) => {
    const { colors } = theme;
    if (active) {
      return css`
        &&&& {
          border: 1px solid ${colors.alpha};
        }
      `;
    }
  }}
`;

const ScheduleTimeContainer = styled.div`
  display: flex;
  gap: ${helper.getSpacing("small")};
`;

const Image = styled.img`
  width: ${helper.getSpacing("medium")};
  height: ${helper.getSpacing("medium")};
  border-radius: 50%;
`;

const UserContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${helper.getSpacing("small")};
  font-size: 15px;
`;

const Action = styled.div`
  display: flex;
  align-items: center;
  gap: ${helper.getSpacing("extraSmall")};
`;

const CriteriaText = styled.span`
  font-family: ${({ theme }) => theme.fonts.poppins};
  padding: ${helper.getSpacing("tiny")};
  color: ${({ theme }) => theme.colors.alpha};
  border-radius: ${helper.getSpacing("tiny")};
  font-size: 14px;
`;

export {
  Container,
  Action,
  UserContainer,
  Image,
  ScheduleTimeContainer,
  CriteriaText,
};
