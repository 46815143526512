import RemoveTarget from "./RemoveTarget";
import CustomerTarget from "./CustomerTarget";
import InfluencerTarget from "./InfluencerTarget";
import { Container } from "./DropTarget.styles";

const DropTarget = () => {
  return (
    <Container>
      <RemoveTarget />
      <CustomerTarget />
      <InfluencerTarget />
    </Container>
  );
};

export default DropTarget;
