import styled from "styled-components";

import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const Container = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: white;
  box-shadow: 0px 4px 10px
    ${({ theme }) => helper.transparentize(theme.colors.alpha)};

  #navigatorIcon {
    font-size: 10px;
    padding: 0.5em;
    i.icon.arrow {
      font-size: inherit;
    }
  }
`;

const Content = styled.div`
  height: 100vh;
  transform-origin: left;
  min-width: max(62.5vw, 768px);
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${helper.getSpacing("small")};
  padding: ${helper.getSpacing("medium")} ${helper.getSpacing("small")};
`;

const Body = styled.div`
  padding: 0 ${helper.getSpacing("medium")};
  display: flex;
  flex-direction: column;
  gap: ${helper.getSpacing("small")};
  height: 89vh;
  max-width: 65vw;
  overflow: scroll;
  ${helper.getStyledScrollbar()};
`;

const Expander = styled.div`
  position: relative;
  height: 100vh;
  width: 5px;
  background-color: ${({ theme }) => theme.colors.alpha};
  #navigatorIcon {
    position: absolute;
    top: 75%;
    right: 0;
    transform: translateY(-50%);
  }
`;

export { Container, Header, Expander, Body, Content };
