import { v4 as uuid } from "uuid";
import { Flex, Text } from "components/core";
import { useLeadNurturing } from "hooks/Twitter";
import { ListContainer } from "./EngagementList.styles";
import NurturingCard from "./NurturingCard";
import Masonry from "react-masonry-css";

const EngagementList = () => {
  const { tweets } = useLeadNurturing();

  if (!tweets.length || !tweets) {
    return (
      <Flex justify="center">
        <Text
          as="span"
          variant="alpha"
          content="No tweets matched with your current keywords!"
        />
      </Flex>
    );
  }

  return (
    <ListContainer>
      <Masonry
        breakpointCols={3}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {tweets.map((tweet) => (
          <NurturingCard tweet={tweet} key={uuid()} />
        ))}
      </Masonry>
    </ListContainer>
  );
};

export default EngagementList;
