import { Dropdown } from "components/core";
import { matchNumberList } from "config/Twitter";
import { IAdminEngagementFormValues } from "context/Twitter/types";
import { useField } from "formik";

interface ISelectMatchNumberProps {
  name: string;
  setMatchNumber: (value: number) => void;
}

const SelectMatchNumber = ({
  name,
  setMatchNumber,
}: ISelectMatchNumberProps) => {
  const [field, , helpers] =
    useField<IAdminEngagementFormValues["matchNumber"]>(name);

  return (
    <Dropdown
      placeholder="0"
      options={matchNumberList}
      selection
      value={field.value.toString()}
      onChange={(e, { value }) => {
        typeof value === "string" && helpers.setValue(parseInt(value));
        typeof value === "string" && setMatchNumber(parseInt(value));
      }}
      style={{ maxWidth: "5rem" }}
    />
  );
};

export default SelectMatchNumber;
