import RemoveTarget from "../LeadGeneration/RemoveTarget";
import FollowTarget from "../LeadGeneration/FollowTarget";
import { Container } from "../LeadGeneration/DropTarget.styles";

const PotentialDropTarget = () => {
  return (
    <Container>
      <RemoveTarget style={{ left: "30%" }} />
      <FollowTarget style={{ right: "30%" }} />
    </Container>
  );
};

export default PotentialDropTarget;
