import { Switch } from "react-router-dom";
import { applicationRoutes as routes } from "config/common";
import { SimpleRoute } from "controllers/common";
import { Redirect } from "components/core";
import { LeadGenerationLayout } from "layout/Twitter";
import {
  KeywordListening,
  Leads,
  LeadNurturing,
  AdminEngagement,
} from "pages/Twitter";

const LeadGeneration = () => {
  return (
    <LeadGenerationLayout>
      <Switch>
        <SimpleRoute exact path={routes.easyTwitter.leadGeneration}>
          <Redirect to={(routes) => routes.leadGeneration.keywordListening} />
        </SimpleRoute>
        <SimpleRoute exact path={routes.leadGeneration.keywordListening}>
          <KeywordListening />
        </SimpleRoute>
        <SimpleRoute exact path={routes.leadGeneration.leads}>
          <Leads />
        </SimpleRoute>
        <SimpleRoute exact path={routes.leadGeneration.leadNurturing}>
          <LeadNurturing />
        </SimpleRoute>
        <SimpleRoute exact path={routes.leadGeneration.admin}>
          <AdminEngagement />
        </SimpleRoute>
      </Switch>
    </LeadGenerationLayout>
  );
};

export default LeadGeneration;
