import { Switch } from "react-router-dom";
import { applicationRoutes as routes } from "config/common";
import { TwitterRoute } from "controllers/Twitter";
import {
  AnalyticsLayout,
  BrandMonitoringLayout,
  TwitterLayout,
  AuditLayout,
  AutoWelcomeLayout,
  InfluencersLayout,
  ScheduleRetweetLayout,
} from "layout/Twitter";
import LeadGenerationRoutes from "./LeadGeneration";
import {
  BrandMonitoring,
  Audit,
  Analytics,
  AutoWelcome,
  Influencers,
  ScheduleRetweet,
} from "pages/Twitter";

const Twitter = () => {
  return (
    <Switch>
      <TwitterRoute
        layout={TwitterLayout}
        path={routes.easyTwitter.leadGeneration}
      >
        <LeadGenerationRoutes />
      </TwitterRoute>
      <TwitterRoute
        layout={TwitterLayout}
        path={routes.easyTwitter.scheduleRetweets}
      >
        <ScheduleRetweetLayout>
          <ScheduleRetweet />
        </ScheduleRetweetLayout>
      </TwitterRoute>
      <TwitterRoute
        layout={TwitterLayout}
        exact
        path={routes.easyTwitter.influencers}
      >
        <InfluencersLayout>
          <Influencers />
        </InfluencersLayout>
      </TwitterRoute>
      <TwitterRoute
        layout={TwitterLayout}
        exact
        path={routes.easyTwitter.analytics}
      >
        <AnalyticsLayout>
          <Analytics />
        </AnalyticsLayout>
      </TwitterRoute>
      <TwitterRoute
        layout={TwitterLayout}
        exact
        path={routes.easyTwitter.autoWelcome}
      >
        <AutoWelcomeLayout>
          <AutoWelcome />
        </AutoWelcomeLayout>
      </TwitterRoute>
      <TwitterRoute
        layout={TwitterLayout}
        exact
        path={routes.easyTwitter.audit}
      >
        <AuditLayout>
          <Audit />
        </AuditLayout>
      </TwitterRoute>
      <TwitterRoute
        layout={TwitterLayout}
        exact
        path={routes.easyTwitter.brandMonitoring}
      >
        <BrandMonitoringLayout>
          <BrandMonitoring />
        </BrandMonitoringLayout>
      </TwitterRoute>
    </Switch>
  );
};

export default Twitter;
