import { ErrorBoundary } from "react-error-boundary";
import { RootRoutes } from "./routes";
import ErrorFallback from "components/common/ErrorFallback";
import { AuthProvider, ProfileProvider, UserTokensProvider } from "context";

const App = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <AuthProvider>
        <UserTokensProvider>
          <ProfileProvider>
            <RootRoutes />
          </ProfileProvider>
        </UserTokensProvider>
      </AuthProvider>
    </ErrorBoundary>
  );
};

export default App;
