import styled from "styled-components";
import { Menu as BaseMenu } from "semantic-ui-react";
import ThemeHelper from "theme/themeHelper";

const helper = new ThemeHelper();

const Menu = styled(BaseMenu)`
  &&& {
    display: flex;
    justify-content: space-between;
    gap: ${helper.getSpacing("small")};
    height: 32px;
    min-height: 24px;
    margin: unset;
  }
`;

const Item = styled(BaseMenu.Item)`
  &&&&&& {
    font-family: ${({ theme }) => theme.fonts.poppins};
    color: ${({ theme }) => theme.colors.lightGrey};
    font-size: 14px;
    &.active {
      ${helper.getFontWeight("medium")}
      border-radius: unset;
      color: ${({ theme }) => theme.colors.alpha};
      background-color: unset;
    }
    &:hover {
      background-color: unset;
    }
  }
`;

export { Menu, Item };
