import { Button, Modal } from "components/core";
import { IModalProps } from "components/core/types";
import { IScheduleRetweetUpdateFormValues } from "context/Twitter/types";
import { Formik } from "formik";
import ScheduleDateTime from "./ScheduleDateTime";
import SelectCriteria from "./SelectCriteria";
import {
  UpdateFormWrapper,
  ButtonWrapper,
  CriteriaWrapper,
} from "./UpdateForm.styles";

interface IUpdatePostFormProps {
  open: IModalProps["open"];
  onClose: IModalProps["onClose"];
  formValues: IScheduleRetweetUpdateFormValues;
  searchTweet: boolean;
  onSubmit: (values: IScheduleRetweetUpdateFormValues) => void;
}

const UpdateForm = ({
  open,
  onClose,
  formValues,
  searchTweet,
  onSubmit,
}: IUpdatePostFormProps) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Formik initialValues={formValues} onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <UpdateFormWrapper>
              <ScheduleDateTime name="datetime" />
              {!searchTweet && (
                <CriteriaWrapper>
                  <SelectCriteria name="highest_liked" />
                  <Button variant="alpha" content="Submit" type="submit" />
                </CriteriaWrapper>
              )}
              {searchTweet && (
                <ButtonWrapper>
                  <Button variant="alpha" content="Submit" type="submit" />
                </ButtonWrapper>
              )}
            </UpdateFormWrapper>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default UpdateForm;
