import { useEffect, useState } from "react";
import { useToggle } from "hooks";
import { useAdminEngagement } from "hooks/Twitter";
import FormCard from "./FormCard";
import {
  BodyWrapper,
  ReplyContainer,
  SectionContainer,
} from "./AdminEngagement.styles";
import ReplyingCard from "./ReplyingCard";

const AdminEngagement = () => {
  const { data } = useAdminEngagement();

  const [isLiked, setIsLiked] = useToggle(data.like);
  const [isReply, setIsReply] = useToggle(data.reply);
  const [replyText, setReplyText] = useState(data.replyText);
  const [matchNumber, setMatchNumber] = useState(data.matchNumber);
  const [keywords, setKeywords] = useState<string[]>([]);

  useEffect(() => {
    setReplyText(data.replyText);
    setKeywords(data.keywords);
    setMatchNumber(data.matchNumber);
  }, [data]);

  return (
    <BodyWrapper>
      <SectionContainer>
        <FormCard
          tweet={data}
          isReplying={isReply}
          setIsReplying={setIsReply}
          setReplyText={setReplyText}
          setKeywords={setKeywords}
          keywords={keywords}
          setMatchNumber={setMatchNumber}
          setIsLiked={setIsLiked}
        />
      </SectionContainer>
      <ReplyContainer>
        <ReplyingCard
          tweet={data}
          isLiked={isLiked}
          isRepling={isReply}
          replyText={replyText}
          keywords={keywords}
          matchNumber={matchNumber}
        />
      </ReplyContainer>
    </BodyWrapper>
  );
};

export default AdminEngagement;
