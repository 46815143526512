import { useMediaQuery } from "@mui/material";
import { Flex, Text } from "components/core";
import { useTwitterAuth } from "hooks";
import { useInfluencers } from "hooks/Twitter";
import Masonry from "react-masonry-css";
import { v4 as uuid } from "uuid";
import { ConnectTwitter } from "../common";
import InfluencerCard from "./InfluencerCard";
import { InfluencerBodyWrapper } from "./InfluencerList.styles";

const InfluencerList = () => {
  const { cards, isTwitterConnected } = useInfluencers();
  const { isSignedIn } = useTwitterAuth();
  const isMobileScreen = useMediaQuery("(max-width: 425px)");
  const isTabletScreen = useMediaQuery("(max-width: 768px)");

  if (isTwitterConnected && !isSignedIn) {
    return <ConnectTwitter connect={isTwitterConnected} />;
  }

  if (cards.length === 0) {
    return (
      <Flex justify="center">
        <Text
          as="span"
          variant="alpha"
          content="You haven't added any Influencers to your list yet, add them from Lead or Potential Customer!"
        />
      </Flex>
    );
  }

  return (
    <InfluencerBodyWrapper>
      <Masonry
        breakpointCols={isMobileScreen ? 1 : isTabletScreen ? 2 : 3}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {cards.map((tweet) => (
          <InfluencerCard tweet={tweet} key={uuid()} />
        ))}
      </Masonry>
    </InfluencerBodyWrapper>
  );
};

export default InfluencerList;
