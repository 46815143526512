import { Button, Text } from "components/core";
import { CardContainer } from "./AdminEngagement.styles";

interface ModalMessageCardProps {
  onDelete: () => void;
}

const ModalMessageCard = ({ onDelete }: ModalMessageCardProps) => {
  return (
    <CardContainer>
      <Text variant="alpha" font="poppins">
        Do you really want to delete? It will delete all your messages and
        behaviour.
      </Text>
      <Button variant="alpha" content="Delete" onClick={onDelete} />
    </CardContainer>
  );
};

export default ModalMessageCard;
