import { useMediaQuery } from "@mui/material";
import { Flex } from "components/core";
import { useKeywordListening } from "hooks/Twitter";
import MultiKeywordSearchBar from "../common/MultiKeywordSearchBar";
import SortBy from "./SortBy";

const TweetSearchBar = () => {
  const { handleSearch } = useKeywordListening();
  const isTabletScreen = useMediaQuery("(min-width: 1024px)");

  return (
    <Flex justify="space-between" padding="none">
      <MultiKeywordSearchBar onSubmit={handleSearch} />
      {isTabletScreen ? <SortBy /> : null}
    </Flex>
  );
};

export default TweetSearchBar;
