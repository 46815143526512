import { useEffect } from "react";
import { usePendo } from "hooks/common";
import { Flex } from "components/core";
import { useAppSelector } from "redux/store/hooks";
import { RecentTweets } from "components/Twitter/RecentTweets";
import {
  LeadsList,
  PotentialDropTarget,
} from "components/Twitter/LeadGeneration";
import { LeadsProvider, RecentTweetsProvider } from "context/Twitter";
import {
  CustomerExportMenu,
  LeadsFilter,
} from "components/Twitter/LeadGeneration/Leads";
import CustomerSortBy from "components/Twitter/LeadGeneration/Leads/CustomerSortBy";
import { ConnectTwitter } from "components/Twitter/common";

const Leads = () => {
  const { initializePendo } = usePendo();

  const connectTwitter = useAppSelector(
    (state) => state.twitter.common.connectTwitter
  );

  useEffect(() => {
    initializePendo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LeadsProvider>
      <Flex justify="end" mt="none">
        <CustomerSortBy />
        <CustomerExportMenu />
        <LeadsFilter />
      </Flex>
      <RecentTweetsProvider>
        <RecentTweets />
      </RecentTweetsProvider>
      {connectTwitter && <ConnectTwitter connect />}
      <PotentialDropTarget />
      <LeadsList />
    </LeadsProvider>
  );
};

export default Leads;
