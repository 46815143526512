import { IconButton, Text } from "components/core";
import { useBoolean } from "hooks/common";
import { useRecentTweetRedux } from "hooks/Twitter";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import RecentTweetCard from "./RecentTweetCard";
import {
  Body,
  Container,
  Content,
  Expander,
  Header,
} from "./RecentTweets.styles";

const RecentTweets = () => {
  const { data, setRecentTweets } = useRecentTweetRedux();
  const location = useLocation();

  const [isSubNavOpen, setSubNavOpen, setSubNavClose] = useBoolean(false);

  useEffect(() => {
    if (data.length) setSubNavOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    setRecentTweets([]);
    setSubNavClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const renderContent = () => {
    if (isSubNavOpen) {
      return (
        <Content>
          <Header>
            <IconButton
              id="navigatorIcon"
              name="arrow right"
              variant="alpha"
              invert
              onClick={() => setSubNavClose()}
            />
            <Text content="Recent Tweets" font="poppins" size="extraSmall" />
          </Header>
          <Body>
            {data.map((tweet) => (
              <RecentTweetCard key={tweet.tweetId} tweet={tweet} />
            ))}
          </Body>
        </Content>
      );
    }

    return (
      <Expander>
        <IconButton
          id="navigatorIcon"
          name="arrow left"
          variant="alpha"
          invert
          onClick={() => setSubNavOpen()}
        />
      </Expander>
    );
  };

  return <Container>{renderContent()}</Container>;
};

export default RecentTweets;
