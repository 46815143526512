import { Form } from "components/core";
import { Formik } from "formik";
import { useLeadNurturing } from "hooks/Twitter";
import { Container } from "./EngagementForm.styles";

const EngagementForm = () => {
  const { handleCreateEngagement, keywords } = useLeadNurturing();

  const initialFormValues = {
    keywords: keywords || [],
  };

  return (
    <Formik
      initialValues={initialFormValues}
      onSubmit={handleCreateEngagement}
      enableReinitialize
    >
      {({ handleSubmit }) => (
        <Form.Form
          onSubmit={handleSubmit}
          style={{ padding: "unset", marginBottom: "1rem" }}
        >
          <Container>
            <Form.MultiSelectDropdownField
              name="keywords"
              placeholder="Enter keywords"
              style={{
                height: "40px",
                width: "560px",
                marginBottom: "unset",
                borderLeft: "unset",
                borderRight: "unset",
                borderTop: "unset",
              }}
            />
            <Form.Button
              variant="alpha"
              type="submit"
              size="tiny"
              content="Apply"
            />
          </Container>
        </Form.Form>
      )}
    </Formik>
  );
};

export default EngagementForm;
