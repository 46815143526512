import { useEffect } from "react";
import { Formik } from "formik";
import ReactTooltip from "react-tooltip";
import { Button, Flex, Form, IconButton } from "components/core";
import { FilterIcon } from "components/Twitter/common/icons";
import { useOnClickOutside } from "hooks";
import {
  setBioIncludes,
  setBioExcludes,
  setLocation,
  setFilterKeywords,
  setShowPotentialFilter,
} from "redux/slices/Twitter";
import { useAppDispatch, useAppSelector } from "redux/store/hooks";
import { CloseIcon, FilterWrapper, FormContainer } from "./LeadsFilter.styles";
import { ILeadsFilterFormValues } from "context/Twitter/types";
import { useLeads } from "hooks/Twitter";

const LeadsFilter = () => {
  const dispatch = useAppDispatch();
  const showPotentialFilter = useAppSelector(
    (state) => state.twitter.potentialCustomer.showPotentialFilter
  );
  const bioIncludes = useAppSelector(
    (state) => state.twitter.potentialCustomer.bioIncludes
  );
  const bioExcludes = useAppSelector(
    (state) => state.twitter.potentialCustomer.bioExcludes
  );
  const location = useAppSelector(
    (state) => state.twitter.potentialCustomer.location
  );
  const filterKeywords = useAppSelector(
    (state) => state.twitter.potentialCustomer.filterKeywords
  );

  const { handleFilterSearch } = useLeads();

  const initialFormValues: ILeadsFilterFormValues = {
    bioIncludes,
    bioExcludes,
    location,
    filterKeywords,
  };

  const handleFormikSearch = ({
    bioIncludes,
    bioExcludes,
    location,
    filterKeywords,
  }: ILeadsFilterFormValues) => {
    handleFilterSearch({
      bioIncludes,
      bioExcludes,
      location,
      filterKeywords,
    });
    dispatch(setShowPotentialFilter(false));
    dispatch(setBioIncludes(bioIncludes));
    dispatch(setBioExcludes(bioExcludes));
    dispatch(setLocation(location));
    dispatch(setFilterKeywords(filterKeywords));
  };

  const onOutSideClick = () => {
    dispatch(setShowPotentialFilter(false));
  };

  const formRef = useOnClickOutside<HTMLDivElement>(onOutSideClick);

  const renderFilterbox = () => {
    if (showPotentialFilter) {
      return (
        <FormContainer ref={formRef}>
          <CloseIcon onClick={onOutSideClick} />
          <Formik
            initialValues={initialFormValues}
            onSubmit={handleFormikSearch}
          >
            {({ handleSubmit }) => (
              <Form.Form onSubmit={handleSubmit}>
                <Form.Field>
                  <Form.Label content="Bio (includes)" />
                  <Form.MultiSelectDropdownField
                    name="bioIncludes"
                    placeholder="Marketing, bitcoin, Start Up"
                    style={{ boxShadow: "unset" }}
                  />
                </Form.Field>
                <Form.Field>
                  <Form.Label content="Bio (excludes)" />
                  <Form.MultiSelectDropdownField
                    name="bioExcludes"
                    placeholder="Marketing, bitcoin, Start Up"
                    style={{ boxShadow: "unset" }}
                  />
                </Form.Field>
                <Form.Field>
                  <Form.Label content="Location" />
                  <Form.MultiSelectDropdownField
                    name="location"
                    placeholder="New Delhi, New York, Tokyo"
                    style={{ boxShadow: "unset" }}
                  />
                </Form.Field>
                <Form.Field>
                  <Form.Label content="Filter Keywords" />
                  <Form.MultiSelectDropdownField
                    name="filterKeywords"
                    placeholder="Marketing, bitcoin, Start Up"
                    style={{ boxShadow: "unset" }}
                  />
                </Form.Field>
                <Flex justify="end" mt="medium">
                  <Button
                    icon="search"
                    variant="alpha"
                    content="Search"
                    type="submit"
                    size="small"
                  />
                </Flex>
              </Form.Form>
            )}
          </Formik>
        </FormContainer>
      );
    }
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <FilterWrapper>
      <IconButton
        variant="transparent"
        icon={FilterIcon}
        data-tip="Filter"
        onClick={() => dispatch(setShowPotentialFilter(true))}
      />
      {renderFilterbox()}
    </FilterWrapper>
  );
};

export default LeadsFilter;
