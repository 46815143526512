import { Fragment } from "react";
import { Navbar } from "components/Home";
import { NftMenu } from "components/NFT";
import { TextSection } from "components/common";

const NFT = () => {
  return (
    <Fragment>
      <Navbar />
      <TextSection
        header="NFT Tweet Analytics"
        description="NFT dashboard helps you to gain insights and make informed decision for your Twitter Marketing."
        style={{ marginBottom: "2rem", marginLeft: "6rem" }}
      />
      <NftMenu />
    </Fragment>
  );
};

export default NFT;
