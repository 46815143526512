import { TweetCard } from "../common";

interface ITweetSearchActionsProps {
  likes: number;
  retweets: number;
  isLiked: boolean;
  onReplyClick: () => void;
  onLikeClick: () => void;
  onEditClick: () => void;
  onRetweetClick: (datetime: Date) => void;
}

const TweetSearchActions = ({
  likes,
  retweets,
  isLiked,
  onReplyClick,
  onLikeClick,
  onEditClick,
  onRetweetClick,
}: ITweetSearchActionsProps) => {
  return (
    <>
      <TweetCard.LikeButton
        count={likes}
        isLiked={isLiked}
        onClick={onLikeClick}
      />
      <TweetCard.ReplyButton onClick={onReplyClick} />
      <TweetCard.RetweetButton count={retweets} onSubmit={onRetweetClick} />
      <TweetCard.EditButton onClick={onEditClick} />
    </>
  );
};

export default TweetSearchActions;
