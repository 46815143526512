import { useState, useEffect, memo } from "react";
import ReactTooltip from "react-tooltip";
import { useDrag } from "react-dnd";
import { useToggle } from "hooks/common";
import { useKeywordListening } from "hooks/Twitter";
import { TweetCard } from "../common";
import { Container } from "./TweetSearchCard.styles";
import TweetReplyCard from "./TweetReplyCard";
import TweetSearchActions from "./TweetSearchActions";
import { ITweetSearchCardProps } from "./types";
import { Linkify } from "components/common";
import { useAppDispatch, useAppSelector } from "redux/store/hooks";
import UserInfoCard from "./UserInfoCard";
import { useTwitterAuth } from "hooks";
import {
  setConnectTwitter,
  setSubNavOpen,
  setTweetText,
} from "redux/slices/Twitter";
import { ItemTypes } from "./ItemTypes";

interface DropResult {
  name: string;
}

const TweetSearchCard = memo(({ tweet }: ITweetSearchCardProps) => {
  const [{ opacity }, drag] = useDrag(() => ({
    type: ItemTypes.CARD,
    item: { id: tweet.tweetId, tweet },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult<DropResult>();
      if (item && dropResult?.name === ItemTypes.CUSTOMER) {
        handlePotential(tweet);
      }
      if (item && dropResult?.name === ItemTypes.INFLUENCER) {
        handleInfluencer(tweet);
      }
      if (item && dropResult?.name === ItemTypes.REMOVE) {
        handleRemove(tweet);
      }
    },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
      handlerId: monitor.getHandlerId(),
    }),
  }));

  const [isTweetReplyCardOpen, toggleTweetReplyCard] = useToggle();
  const { isSignedIn } = useTwitterAuth();
  const dispatch = useAppDispatch();
  const {
    handleRemove,
    handleLikeTweet,
    handleRetweet,
    handleScheduleRetweet,
    handleReply,
    handleInfluencer,
    handlePotential,
    handleLeadTweets,
  } = useKeywordListening();
  const [files, setFiles] = useState<File[]>([]);
  const [text, setText] = useState("");
  const [showProfile, setShowProfile] = useState(false);
  const postNow = useAppSelector((state) => state.twitter.tweetSearch.postNow);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <Container ref={drag} style={{ opacity }}>
      <TweetCard>
        <TweetCard.Content>
          <TweetCard.Head>
            <TweetCard.Image
              src={tweet.profilePicUrl}
              alt={tweet.username}
              data-tip="Last 20 Tweets"
              onClick={() => handleLeadTweets({ leadUsername: tweet.username })}
            />
            <TweetCard.Username
              dataTip="Check User Profile"
              name={tweet.name}
              username={tweet.username}
              keywords={tweet.keywords}
              createdAt={new Date(tweet.createdAt)}
              onClick={() => setShowProfile(true)}
            />
          </TweetCard.Head>
          <TweetCard.TSBio>
            <Linkify>{tweet.text}</Linkify>
          </TweetCard.TSBio>
          <TweetCard.Action>
            <TweetSearchActions
              likes={tweet.likes}
              retweets={tweet.retweets}
              isLiked={tweet.isLiked}
              onLikeClick={() => {
                if (isSignedIn) {
                  handleLikeTweet(tweet);
                } else {
                  dispatch(setConnectTwitter(true));
                }
              }}
              onReplyClick={() => {
                if (isSignedIn) {
                  toggleTweetReplyCard();
                } else {
                  dispatch(setConnectTwitter(true));
                }
              }}
              onRetweetClick={(datetime) => {
                if (isSignedIn) {
                  if (postNow) {
                    handleRetweet({
                      tweetId: tweet.tweetId,
                      datetime,
                    });
                  } else {
                    handleScheduleRetweet({
                      tweetId: tweet.tweetId,
                      twitterUsername: tweet.username,
                      datetime,
                    });
                  }
                } else {
                  dispatch(setConnectTwitter(true));
                }
              }}
              onEditClick={() => {
                dispatch(setTweetText(tweet.text));
                dispatch(setSubNavOpen());
              }}
            />
          </TweetCard.Action>
        </TweetCard.Content>
      </TweetCard>
      <TweetReplyCard
        tweetId={tweet.tweetId}
        tweetText={tweet.text}
        open={isTweetReplyCardOpen}
        onCancel={() => toggleTweetReplyCard(false)}
        files={files}
        onFilesChange={(files) => setFiles(files)}
        text={text}
        onTextChange={(value) => setText(value)}
        onSave={() => {
          handleReply({ tweetId: tweet.tweetId, files, text });
          setText("");
          setFiles([]);
          toggleTweetReplyCard(false);
        }}
      />
      <UserInfoCard
        open={showProfile}
        onClose={() => setShowProfile(false)}
        name={tweet.name}
        username={tweet.username}
        profileImage={tweet.profilePicUrl}
        bio={tweet.bio}
        followers={tweet.followers}
        followings={tweet.followings}
        url={tweet.profileUrl}
        website={tweet.website}
        totalTweets={tweet.tweets}
        location={tweet.location}
      />
    </Container>
  );
});

export default TweetSearchCard;
