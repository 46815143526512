import { Icon, Text } from "components/core";
import { ReplyingCard as Card } from "components/Twitter/common";
import { IAdminEngagementResult } from "adapters/Twitter/types";
import { EmptyHeartIcon, FilledHeartIcon } from "../../common/icons";

interface CardProps {
  tweet: IAdminEngagementResult;
  isLiked: boolean;
  isRepling: boolean;
  replyText: string;
  matchNumber: number;
  keywords: string[];
}

const ReplyingCard = ({
  tweet,
  isRepling,
  replyText,
  keywords,
  matchNumber,
  isLiked,
}: CardProps) => {
  return (
    <>
      <Card>
        <Card.Sidebar>
          <Card.Image
            src="https://randomuser.me/api/portraits/men/77.jpg"
            alt="Timmothy Burke"
          />
          {isRepling && <Card.Line variant="pictonBlue" />}
        </Card.Sidebar>
        <Card.Content>
          <Card.Head>
            <Card.ReplyingUsername
              name="Timmothy Burke"
              username="timmothy.burke"
            />
          </Card.Head>
          <Card.Bio>
            Lorem ipsum dolor{" "}
            {matchNumber !== 0 && (
              <Text as="span" variant="alpha">
                {keywords.slice(0, matchNumber).join(" ")}
              </Text>
            )}{" "}
            Lorem ipsum dolor Lorem ipsum dolor elit.
          </Card.Bio>
          <Card.Action>
            <Icon icon={isLiked ? FilledHeartIcon : EmptyHeartIcon} />
          </Card.Action>
        </Card.Content>
      </Card>
      {isRepling && (
        <Card>
          <Card.Sidebar>
            <Card.Image src={tweet.imageUrl} alt={tweet.name} />
          </Card.Sidebar>
          <Card.Content>
            <Card.Head>
              <Card.ReplyingUsername
                name={tweet.name}
                username={tweet.twitterUsername}
              />
            </Card.Head>
            <Card.Bio>{replyText}</Card.Bio>
          </Card.Content>
        </Card>
      )}
    </>
  );
};

export default ReplyingCard;
